import { Card, CardBody } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import React, { FC } from 'react';

import { CAMPAIGN_STATUSES } from 'utils/models/Campaign';
import { Campaign } from 'types';
import ValidatedForm from 'views/Widgets/Forms/ValidatedForm';

interface Props {
  object: any;
  onCallback: (data: any) => void;
  campaign: Campaign;
}

const PerformanceStepAssessManagerListNonElibigle: FC<Props> = ({
  object,
  campaign,
  onCallback,
}) => {
  const { formatMessage } = useIntl();

  return (
    <>
      {' '}
      <Card>
        <CardBody>
          <FormattedMessage
            id="app.views.performance.performance_step_assess_manager.other.not_eligible_for_feedback.decription"
            defaultMessage="
                You do not need to provide feedback to your manager this cycle.
                This is because either you or your manager are not eligible for
                this cycle. If you have any questions, please contact your HR
                Business Partner.
              "
          />
        </CardBody>
      </Card>
      <ValidatedForm
        buttonClassName="mt-3"
        method={object.id ? 'PATCH' : 'POST'}
        url={
          // @ts-expect-error
          campaign.status === CAMPAIGN_STATUSES.DEMO
            ? undefined
            : 'survey-responses'
        }
        buttonIsBlock={false}
        object={object}
        inputs={[]}
        callback={onCallback}
        submitText={formatMessage({
          id: 'app.views.performance.performance_step_assess_manager_list.submit_text.continue',
          defaultMessage: 'Continue',
        })}
      />
    </>
  );
};

export default React.memo(PerformanceStepAssessManagerListNonElibigle);
