import { Campaign, ReduxState } from 'types';
import React, { FC, useMemo } from 'react';

import PerformanceStepAssessManager from './PerformanceStepAssessManager';
import PerformanceStepAssessManagerList from './PerformanceStepAssessManagerList';
import { connect } from 'react-redux';
import { getManagersEligibleForUpwardFeedback } from 'utils/models/Performance';
import { useFeatures } from 'utils/util/features';

interface Props
  extends Pick<ReduxState, 'me' | 'currentOrganization' | 'demoPeople'> {
  campaign: Campaign;
  setCampaign: (campaign: Campaign) => void;
}

const PerformanceStepAssessManagerProxy: FC<Props> = (props) => {
  const { ifEnabled } = useFeatures();
  const campaign = props.campaign;

  const showMultipleManagers = useMemo(() => {
    if (!ifEnabled('additional_managers', true, false)) {
      return false;
    }
    const managers = getManagersEligibleForUpwardFeedback(
      props.me,
      campaign,
      // @ts-expect-error
      campaign.relationships,
      props.demoPeople
    );

    const hasDirectManager = !!managers.find(
      (manager) => !manager.isAdditionalManager
    );

    return managers.length > 1 || !hasDirectManager;
  }, [campaign, ifEnabled, props.demoPeople, props.me]);

  return showMultipleManagers ? (
    <PerformanceStepAssessManagerList {...props} />
  ) : (
    <PerformanceStepAssessManager {...props} />
  );
};

const mapStateToProps = (
  state: ReduxState
): Pick<ReduxState, 'me' | 'currentOrganization' | 'demoPeople'> => {
  const { me, currentOrganization, demoPeople } = state;

  return {
    me,
    currentOrganization,
    demoPeople,
  };
};

export default connect(mapStateToProps)(
  React.memo(PerformanceStepAssessManagerProxy)
);
