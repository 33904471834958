import { Button, Col, Row } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import React, { FC } from 'react';

import ValidatedForm from 'views/Widgets/Forms/ValidatedForm';

const PerformanceStepAssessManagerListProceedButton: FC<{
  currentManager: any;
  managersRemaining: number;
  object: any;
  isDemoOrPreviewMode: boolean;
  callback: (data: any) => void;
}> = ({
  currentManager,
  managersRemaining,
  object,
  isDemoOrPreviewMode,
  callback,
}) => {
  const { formatMessage } = useIntl();
  return (
    <>
      {!currentManager && managersRemaining > 0 && (
        <Row>
          <Col>
            <span className="text-muted me-4">
              <FormattedMessage
                id="app.views.performance.performance_step_assess_manager_list.managers_remaining"
                defaultMessage="{managersRemaining, plural, one {1 manager left} other {{managersRemaining} managers left}} "
                values={{ managersRemaining: managersRemaining }}
              />
            </span>
            <Button className="mt-0" color="primary" disabled>
              <FormattedMessage
                id="app.views.performance.performance_step_assess_manager_list.button.continue"
                defaultMessage="Continue"
              />
            </Button>
          </Col>
        </Row>
      )}
      {!currentManager && managersRemaining === 0 && (
        <ValidatedForm
          method={object.id ? 'PATCH' : 'POST'}
          url={isDemoOrPreviewMode ? undefined : 'survey-responses'}
          buttonIsBlock={false}
          object={object}
          inputs={[]}
          callback={callback}
          submitText={formatMessage({
            id: 'app.views.performance.performance_step_assess_manager_list.submit_text.save_and_continue',
            defaultMessage: 'Save and continue',
          })}
        />
      )}
    </>
  );
};

export default React.memo(PerformanceStepAssessManagerListProceedButton);
