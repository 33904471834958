import {
  PERFORMANCE_FEATURE_ASSESS_ORGANIZATION_OPEN_RESPONSE_QUESTIONS,
  PERFORMANCE_FEATURE_COMPLETE_OBJECTIVES_FOR_QUARTER_STEP,
  PERFORMANCE_FEATURE_DISABLE_CONVERSATION_ACKNOWLEDGEMENTS,
  PERFORMANCE_FEATURE_EVALUATION_OPEN_RESPONSE_QUESTIONS,
  PERFORMANCE_FEATURE_OTHERS_OPEN_RESPONSE_QUESTIONS,
  PERFORMANCE_FEATURE_PEER_FEEDBACK,
  PERFORMANCE_FEATURE_PEER_FEEDBACK_ASSIGNMENT_TIME,
  PERFORMANCE_FEATURE_SELF_OPEN_RESPONSE_QUESTIONS,
  PERFORMANCE_FEATURE_SHOW_PERFORMANCE_PERIOD_WITH_SPECIFIC_DATES,
  PERFORMANCE_FEATURE_UPWARD_MANAGER_OPEN_RESPONSE_QUESTIONS,
  PHASE_TYPE_CALIBRATION,
  PHASE_TYPE_REPORTING,
  getCampaignHasFeatureEnabled,
  getPhaseDateHasPassed,
  getPhaseDateHasStarted,
  getPhasePrefix,
} from './Performance';
import {
  formatDateWithUnicode,
  getDatePart,
  parseDateFromUnicode,
} from '../util/time';
import { getUnattributedPerson, peopleObjectsAreEqual } from './Person';

import { Campaign } from '../../types';
import ConfirmAPI from 'utils/api/ConfirmAPI';
import { INPUT_TYPES } from '../../views/Widgets/Inputs/ValidatedInputTypes';
import { Link } from 'react-router-dom';
import { RELATIONSHIP_TYPES } from './RelationshipUtils';
import React from 'react';
import { findNearestTimeFrame } from './TimeFrame';
import { performanceFlowForEndUsersIsEnabled } from 'utils/util/features';
import { uniqBy } from 'lodash';

export const CAMPAIGN_PHASE_TOKEN_VALIDITY_HOURS = 72;

export const CAMPAIGN_TYPES = {
  PERFORMANCE: 'P',
};

export const CAMPAIGN_STATUSES = {
  DEMO: 'D',
  INACTIVE: 'I',
  ACTIVE: 'A',
};

export const getCampaignStatusDisplayName = (status, formatMessage) => {
  if (status === CAMPAIGN_STATUSES.ACTIVE) {
    return formatMessage({
      id: 'app.utils.models.campaign.status.name.active',
      defaultMessage: 'Published',
    });
  }
  if (status === CAMPAIGN_STATUSES.DEMO) {
    return formatMessage({
      id: 'app.utils.models.campaign.status.name.demo',
      defaultMessage: 'Demo',
    });
  }
  if (status === CAMPAIGN_STATUSES.INACTIVE) {
    return formatMessage({
      id: 'app.utils.models.campaign.status.name.inactive',
      defaultMessage: 'Unpublished',
    });
  }

  console.error('Unrecognized campaign status: ' + status);
  return '';
};

export const getCampaignStatusDisplayNameWithIcon = (status, formatMessage) => {
  if (status === CAMPAIGN_STATUSES.ACTIVE) {
    return (
      <span>
        <i className="fe fe-check me-2" />
        {getCampaignStatusDisplayName(status, formatMessage)}
      </span>
    );
  }
  if (status === CAMPAIGN_STATUSES.DEMO) {
    return (
      <span>
        <i className="fe fe-alert-triangle me-2" />
        {getCampaignStatusDisplayName(status, formatMessage)}
      </span>
    );
  }
  if (status === CAMPAIGN_STATUSES.INACTIVE) {
    return (
      <span>
        <i className="fe fe-edit me-2" />
        {getCampaignStatusDisplayName(status, formatMessage)}
      </span>
    );
  }

  console.error('Unrecognized campaign status: ' + status);
  return '';
};

// NOTE: we don't allow CAMPAIGN_STATUSES.DEMO to be set via the UI
// because it's only used when previewing campaigns
// (in the future we could remove the status from our backend and
// only honor it on the frontend when set in preview mode)
export const CAMPAIGN_STATUSES_LIST = (formatMessage) => [
  {
    id: CAMPAIGN_STATUSES.INACTIVE,
    name: getCampaignStatusDisplayName(
      CAMPAIGN_STATUSES.INACTIVE,
      formatMessage
    ),
  },
  {
    id: CAMPAIGN_STATUSES.ACTIVE,
    name: getCampaignStatusDisplayName(CAMPAIGN_STATUSES.ACTIVE, formatMessage),
  },
];

export const getPhaseByType = (campaign, phaseType) => {
  return campaign?.phases?.find((p) => p.type === phaseType);
};

export const getPhaseIndexByType = (campaign, phaseType) => {
  return campaign?.phases?.findIndex((p) => p.type === phaseType);
};

export const hasPhase = (campaign, phaseType) => {
  // return boolean true/false if campaign has specified phase
  return !!getPhaseByType(campaign, phaseType);
};

export const isEngagementSurveyOnly = (campaign: Campaign) => {
  return campaign?.properties?.is_engagement_survey_only ?? false;
};

export const campaignIsActiveOrDemoPerfCampaign = (campaign) => {
  return (
    campaign.type === CAMPAIGN_TYPES.PERFORMANCE &&
    (campaign.status === CAMPAIGN_STATUSES.ACTIVE ||
      campaign.status === CAMPAIGN_STATUSES.DEMO)
  );
};

export const getRelationships = (
  campaign,
  relationshipType,
  trustUserOverDataset = false
) => {
  const relationships = campaign?.relationships
    ? campaign?.relationships.filter((r) => r.type === relationshipType)
    : [];

  if (trustUserOverDataset) {
    // if user has specified any relationships, take those instead
    // of the dataset ones
    const userSpecifiedRelationships = relationships?.filter((r) => !r.dataset);

    return userSpecifiedRelationships?.length > 0
      ? userSpecifiedRelationships
      : relationships;
  } else {
    return relationships;
  }
};

export const getRelevantRelationshipsForDirectReports = (campaign) => {
  const relevantRelationshipsDirectReports = getRelationships(
    campaign,
    RELATIONSHIP_TYPES.HAS_DIRECT_REPORT_FEEDBACK_FOR
  );

  const relevantRelationshipsAdditionalDirectReports = getRelationships(
    campaign,
    RELATIONSHIP_TYPES.HAS_ADDITIONAL_MANAGER_DIRECT_REPORT_FEEDBACK_FOR
  );

  return [
    ...relevantRelationshipsDirectReports,
    ...relevantRelationshipsAdditionalDirectReports,
  ];
};

export const getRelevantRelationshipsForMultipleManagers = (campaign) => {
  const relevantRelationshipsDirectManager = getRelationships(
    campaign,
    RELATIONSHIP_TYPES.REPORTS_TO
  );

  const relevantRelationshipsAdditionalManagers = getRelationships(
    campaign,
    RELATIONSHIP_TYPES.REPORTS_TO_ADDITIONAL_MANAGER
  );

  return [
    ...relevantRelationshipsDirectManager,
    ...relevantRelationshipsAdditionalManagers,
  ];
};

export const getPeopleOnReceivingEnd = (
  campaign,
  relationshipType,
  fromPerson = null,
  trustUserOverDataset = false
) => {
  let relationships = getRelationships(
    campaign,
    relationshipType,
    trustUserOverDataset
  );
  if (fromPerson) {
    relationships = relationships?.filter((r) =>
      peopleObjectsAreEqual(fromPerson, r.from_person)
    );
  }

  return relationships?.map((r) => r.to_person);
};

export const getPeopleOnSendingEnd = (
  campaign,
  relationshipType,
  toPerson = null,
  trustUserOverDataset = false
) => {
  let relationships = getRelationships(
    campaign,
    relationshipType,
    trustUserOverDataset
  );
  if (toPerson) {
    relationships = relationships?.filter((r) =>
      peopleObjectsAreEqual(toPerson, r.to_person)
    );
  }

  return relationships?.map((r) => r.from_person);
};

export const getManagerPerson = (
  directReport,
  campaign,
  demoPeople,
  trustUserOverDataset = false
) => {
  if (!trustUserOverDataset) {
    // pull from dataset ONLY and return if found
    const datasetManagerRelationships = campaign?.relationships?.filter(
      (r) =>
        r.dataset &&
        r.type === RELATIONSHIP_TYPES.REPORTS_TO &&
        peopleObjectsAreEqual(r.from_person, directReport)
    );
    if (datasetManagerRelationships?.length > 0) {
      const managerRelationship = datasetManagerRelationships[0];
      return transformToSuveyTarget(managerRelationship);
    }
  }

  // pull from what the user reported
  const managers = getPeopleOnReceivingEnd(
    campaign,
    RELATIONSHIP_TYPES.REPORTS_TO,
    directReport,
    trustUserOverDataset
  );

  if (campaign.status === CAMPAIGN_STATUSES.DEMO && !(managers?.length > 0)) {
    return demoPeople[0];
  } else {
    return managers?.length > 0 ? managers[0] : null;
  }
};

export const getAdditionalManagersPeople = (
  directReport,
  campaign,
  demoPeople
) => {
  // pull from what the user reported
  const managers = uniqBy(
    getPeopleOnReceivingEnd(
      campaign,
      RELATIONSHIP_TYPES.REPORTS_TO_ADDITIONAL_MANAGER,
      directReport
    ) ?? [],
    'id'
  );

  if (campaign.status === CAMPAIGN_STATUSES.DEMO && !(managers?.length > 0)) {
    return demoPeople.slice(6, 7);
  } else {
    return managers;
  }
};

export const getBelievedAdditionalManagersPeople = (
  directReport,
  campaign,
  demoPeople
) => {
  // pull from what the user reported
  const believedManagers = uniqBy(
    getPeopleOnReceivingEnd(
      campaign,
      RELATIONSHIP_TYPES.BELIEVES_TO_REPORT_TO_ADDITIONAL_MANAGER,
      directReport
    ) ?? [],
    'id'
  );

  if (
    campaign.status === CAMPAIGN_STATUSES.DEMO &&
    !(believedManagers?.length > 0)
  ) {
    return demoPeople.slice(6, 7);
  } else {
    return believedManagers;
  }
};

export const replaceRelationships = (
  campaign,
  relationshipTypes,
  newRelationships,
  fromPerson = null,
  toPerson = null,
  isDatasetReplacement = false
) => {
  // keep only those that either came from a dataset or
  // don't match these types (and, if fromPerson
  // is provided, those that don't have this fromPerson, or same if
  // the toPerson is provided)
  const remainingRelationships = campaign.relationships
    ? campaign.relationships.filter((r) => {
        // keep if from a dataset
        if (
          (r.dataset && !isDatasetReplacement) ||
          (!r.dataset && isDatasetReplacement)
        ) {
          return true;
        }

        // keep if a different relationship
        if (relationshipTypes.indexOf(r.type) === -1) {
          return true;
        }

        // no from/to people specified, so
        // if we've gotten this far, it's one of ours
        // so we should delete it
        if (!fromPerson && !toPerson) {
          return false;
        }

        if (fromPerson && toPerson) {
          // need to match both to throw away
          return !(
            peopleObjectsAreEqual(fromPerson, r.from_person) &&
            peopleObjectsAreEqual(toPerson, r.to_person)
          );
        }

        // just need to match from person to throw away
        if (fromPerson) {
          return !peopleObjectsAreEqual(fromPerson, r.from_person);
        }

        // just need to match to person to throw away
        if (toPerson) {
          return !peopleObjectsAreEqual(toPerson, r.to_person);
        }

        // no other reasons to filter it out, so keep it
        return true;
      })
    : [];

  // return the remaining plus the new types
  return {
    ...campaign,
    relationships: [...newRelationships, ...remainingRelationships],
  };
};

export const getPerfCampaignIsInReportingPhaseOrClosed = (campaign) => {
  // Check the last phase by default.
  let phaseIndexToCheck = campaign.phases.length - 1;

  // If the last phase is REPORTING check the previous one.
  if (
    campaign.phases[campaign.phases.length - 1].type === PHASE_TYPE_REPORTING
  ) {
    phaseIndexToCheck = campaign.phases.length - 2;
  }

  if (phaseIndexToCheck < 0) {
    return true;
  }

  // If the phase is closed, consider the entire campaign closed.
  return getPhaseIsClosed(campaign, phaseIndexToCheck);
};

export const getPerfCampaignIsClosed = (campaign) => {
  // Check the last phase by default.
  const phaseIndexToCheck = campaign.phases.length - 1;

  if (phaseIndexToCheck < 0) {
    return true;
  }

  // If the phase is closed, consider the entire campaign closed.
  return getPhaseIsClosed(campaign, phaseIndexToCheck);
};

export const getPerfCampaignYetToOpen = (campaign) => {
  // Check the first phase by default.
  const phaseIndexToCheck = 0;

  // If first phase has not opened yet, consider the campaign yet to open
  return !getPhaseOpenedInThePast(campaign, phaseIndexToCheck);
};

export const getPhaseOpenedInThePast = (campaign, phaseIndex) => {
  if (!campaign?.phases) {
    return false;
  }

  if (campaign?.phases?.length <= phaseIndex) {
    // if this is above our defined phases, it means perf is closed,
    // so this "final phase" that goes indefinitely is considered open
    return getPerfCampaignIsClosed(campaign);
  }

  const phase = campaign.phases[phaseIndex];

  const phaseIsOpen =
    campaign.status === CAMPAIGN_STATUSES.DEMO ||
    (phase?.start_date && getPhaseDateHasStarted(phase?.start_date));

  return phaseIsOpen;
};

export const getPerfCampaignIsOpen = (campaign) => {
  return (
    campaign &&
    campaign.status === CAMPAIGN_STATUSES.ACTIVE &&
    campaign.phases?.length > 0 &&
    getPhaseOpenedInThePast(campaign, 0) &&
    !getPerfCampaignIsClosed(campaign)
  );
};

export const getCurrentPhaseIndex = (campaign) => {
  // if only one phase, stay in that phase forever
  // (as there's no separate reporting/conversations
  // phase, e.g. to show in the perf admin, etc.)
  if (campaign.phases.length === 1) {
    return 0;
  }

  // When perf is closed, the campaign will be in an implicit "reporting" phase
  // with an index value of 1 greater than the # of explicitly defined phases.
  // So as we search for the most recent open phase we should start with
  // this implicit "reporting" phase and iterate backward, instead of the
  // normal last index of a Javascript array (which would be length - 1).
  let phaseIndex = campaign.phases.length;

  while (phaseIndex > 0) {
    if (getPhaseOpenedInThePast(campaign, phaseIndex)) {
      break;
    }
    phaseIndex--;
  }

  return phaseIndex;
};

export const getPhaseIsOpen = (campaign, phaseIndex) => {
  const phase = campaign.phases[phaseIndex];

  const phaseClosedInThePast =
    campaign.status !== CAMPAIGN_STATUSES.DEMO &&
    getPhaseDateHasPassed(phase?.end_date);

  const phaseIsOpen =
    campaign.status === CAMPAIGN_STATUSES.DEMO ||
    (new Date(phase?.start_date) <= new Date() && !phaseClosedInThePast);

  return phaseIsOpen;
};

export const getPhaseIsClosed = (campaign, phaseIndex) => {
  if (!campaign?.phases) {
    return false;
  }

  const phase = campaign.phases[phaseIndex];

  return (
    campaign.status !== CAMPAIGN_STATUSES.DEMO &&
    getPhaseDateHasPassed(phase?.end_date)
  );
};

// Ensures the campaign is in a non-Calibration/Reporting phase
export const getPerfCampaignIsInActionPhase = (campaign) => {
  if (campaign) {
    const currentPhaseIndex = getCurrentPhaseIndex(campaign);
    const currentPhase = campaign.phases[currentPhaseIndex];

    if (
      currentPhase?.type !== PHASE_TYPE_REPORTING &&
      currentPhase?.type !== PHASE_TYPE_CALIBRATION &&
      getPhaseIsOpen(campaign, currentPhaseIndex)
    ) {
      return true;
    }
  }
  return false;
};

export const getPerfCampaignOpenActionPhases = (campaign) => {
  if (campaign && campaign?.phases) {
    return campaign.phases.filter(
      (phase, index) =>
        phase?.type !== PHASE_TYPE_REPORTING &&
        phase?.type !== PHASE_TYPE_CALIBRATION &&
        getPhaseIsOpen(campaign, index)
    );
  }

  return [];
};

const getReportingPhaseName = (campaign, formatMessage) => {
  if (
    // if conversations is explicitly disabled, or there is no
    // Reporting aka Conversations phase even defined
    !hasPhase(campaign, PHASE_TYPE_REPORTING) ||
    getCampaignHasFeatureEnabled(
      campaign,
      PERFORMANCE_FEATURE_DISABLE_CONVERSATION_ACKNOWLEDGEMENTS
    )
  ) {
    // if conversations are disabled, this is just a reporting phase
    return formatMessage({
      id: 'app.utils.models.campaign.phase.name.reports',
      description:
        'as in "performance reports", comes before the word "released" in the UI',
      defaultMessage: 'reports',
    });
  } else {
    return formatMessage({
      id: 'app.utils.models.campaign.phase.name.conversations',
      defaultMessage: 'conversations',
    });
  }
};

export const getPhaseName = (campaign, phaseIndex, formatMessage) => {
  if (!campaign?.phases.length) {
    return formatMessage({
      id: 'app.utils.models.campaign.phase.name.phaseI',
      defaultMessage: 'phase I',
    });
  }

  // final reporting phase (either declared explicitly
  // or implied)
  if (phaseIndex >= campaign.phases.length) {
    return getReportingPhaseName(campaign, formatMessage);
  }
  const phase = campaign.phases[phaseIndex];
  if (phase?.type === PHASE_TYPE_REPORTING) {
    return getReportingPhaseName(campaign, formatMessage);
  }

  // calibration phase
  if (phase?.type === PHASE_TYPE_CALIBRATION) {
    return formatMessage({
      id: 'app.utils.models.campaign.phase.name.calibration',
      defaultMessage: 'calibration',
    });
  }

  if (phaseIndex === 1) {
    return formatMessage({
      id: 'app.utils.models.campaign.phase.name.phaseII',
      defaultMessage: 'phase II',
    });
  } else if (phaseIndex === 2) {
    return formatMessage({
      id: 'app.utils.models.campaign.phase.name.phaseIII',
      defaultMessage: 'phase III',
    });
  }

  // default to phase I
  return formatMessage({
    id: 'app.utils.models.campaign.phase.name.phaseI',
    defaultMessage: 'phase I',
  });
};

export const getAttributedOrUnattributedPersonHeading = (
  formatMessage,
  person,
  attributedPersonIndex,
  attributionType,
  anonymous
) => {
  return (
    <>
      {person?.id && (
        <span className="fw-bold small">
          <Link
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: 'inherit' }}
            to={person.url}
          >
            {person.full_name}
          </Link>
          {person.title && (
            <span className="fw-normal">
              {', '}
              {person.title}
            </span>
          )}
        </span>
      )}
      {!person?.id && (
        <span className="fw-bold small">
          {
            getUnattributedPerson(
              formatMessage,
              attributedPersonIndex,
              attributionType,
              anonymous
            ).full_name
          }
        </span>
      )}
    </>
  );
};

export const getCampaignCoverageDurationMonths = (campaign) => {
  if (!campaign?.coverage_start_date || !campaign?.coverage_end_date) {
    return null;
  }

  const milliSecondsInMonth = 1000 * 60 * 60 * 24 * 30;

  // Round to nearest month
  const months = Math.round(
    (Date.parse(campaign?.coverage_end_date) -
      Date.parse(campaign?.coverage_start_date)) /
      milliSecondsInMonth
  );

  // Set a floor for at least 1 month
  return Math.max(months, 1);
};

export const getCampaignCoverageDurationMonthString = (
  campaign,
  formatMessage
) => {
  // if this campaign has the feature to show an explicit promotion period
  // with hover dates (e.g. for when the dates don't fall nicely in terms of
  // beginning/ending of a given month/quater), show this as a hyperlink instead
  if (
    getCampaignHasFeatureEnabled(
      campaign,
      PERFORMANCE_FEATURE_SHOW_PERFORMANCE_PERIOD_WITH_SPECIFIC_DATES
    )
  ) {
    return formatMessage({
      id: 'app.utils.models.campaign.performance.period',
      defaultMessage: 'performance period',
    });
  }

  let count = -1; // Can be either string or int;

  if (campaign?.coverage_start_date && campaign?.coverage_end_date) {
    count = getCampaignCoverageDurationMonths(campaign) ?? -1;
  }

  return formatMessage(
    {
      defaultMessage:
        '{type, select, one {{count} month} many {{count} months} other {several months}}',
      id: 'app.utils.models.campaign.months',
    },
    {
      type: count === 1 ? 'one' : count >= 0 ? 'many' : 'other',
      count,
    }
  );
};

export const getMMYYY = (date, showYear = true, locale = 'en-US') => {
  // e.g. "Jan 2022"
  const month = date.toLocaleString(locale, { month: 'long' });

  return month + (showYear ? ' ' + date.getFullYear() : '');
};

export const getCampaignCoverageDateString = (campaign) => {
  // e.g. "Jan 2022 to Aug 2022"
  if (!campaign?.coverage_start_date || !campaign?.coverage_end_date) {
    return 'cycle';
  }

  // Note the appended replace is to counteract JS's default
  // assumption that all dates w/o timezones are in GMT
  // https://stackoverflow.com/questions/7556591/is-the-javascript-date-object-always-one-day-off
  const startDate = new Date(campaign.coverage_start_date.replace(/-/g, '/'));
  const endDate = new Date(campaign.coverage_end_date.replace(/-/g, '/'));

  const showYear = !(startDate.getFullYear() === endDate.getFullYear());

  return getMMYYY(startDate, showYear) + ' to ' + getMMYYY(endDate);
};

export const getCustomQuestionsByPhase = (campaign) => {
  const phaseQuestionMapping = {
    S: [PERFORMANCE_FEATURE_SELF_OPEN_RESPONSE_QUESTIONS],
    O: [
      PERFORMANCE_FEATURE_UPWARD_MANAGER_OPEN_RESPONSE_QUESTIONS,
      PERFORMANCE_FEATURE_OTHERS_OPEN_RESPONSE_QUESTIONS,
      PERFORMANCE_FEATURE_ASSESS_ORGANIZATION_OPEN_RESPONSE_QUESTIONS,
    ],
    E: [PERFORMANCE_FEATURE_EVALUATION_OPEN_RESPONSE_QUESTIONS],
  };

  const result = {};
  campaign?.phases?.forEach((p) => {
    if (p?.type in phaseQuestionMapping) {
      const customQuestionFields = phaseQuestionMapping[p?.type];
      if (Array.isArray(customQuestionFields)) {
        for (const field of customQuestionFields) {
          if (field in p) {
            if (!result[p.type]) {
              result[p.type] = {};
            }
            result[p.type][field] = p[field];
          }
        }
      }
    }
  });

  for (const qType in result) {
    const phaseCategory = result[qType];
    for (const category in phaseCategory) {
      if (Array.isArray(phaseCategory[category])) {
        const questionList: object[] = [];
        for (let i = 0; i < phaseCategory[category].length; i++) {
          const labelObj = getQuestionLabels(result[qType][category][i]);

          // we may need to add multiple, e.g. for Likert
          // where one "question" is really multiple questions
          Object.keys(labelObj).forEach((key) => {
            questionList.push({
              type: result[qType][category][i]?.type,
              label: labelObj[key],
              key: key,
            });
          });
        }
        result[qType][category] = questionList;
      }
    }
  }

  return result;
};

export const getAllCustomQuestions = (campaign) => {
  const phaseQuestionMapping = {
    S: PERFORMANCE_FEATURE_SELF_OPEN_RESPONSE_QUESTIONS,
    O: [
      PERFORMANCE_FEATURE_UPWARD_MANAGER_OPEN_RESPONSE_QUESTIONS,
      PERFORMANCE_FEATURE_OTHERS_OPEN_RESPONSE_QUESTIONS,
      PERFORMANCE_FEATURE_ASSESS_ORGANIZATION_OPEN_RESPONSE_QUESTIONS,
    ],
    E: PERFORMANCE_FEATURE_EVALUATION_OPEN_RESPONSE_QUESTIONS,
  };

  let result = [];
  campaign?.phases?.forEach((p) => {
    if (p?.type in phaseQuestionMapping) {
      const customQuestionFields = phaseQuestionMapping[p?.type];
      if (typeof customQuestionFields === 'string') {
        result = result.concat(p[customQuestionFields]);
      }
      if (Array.isArray(customQuestionFields)) {
        for (const field of customQuestionFields) {
          if (field in p) {
            result = result.concat(p[field]);
          }
        }
      }
    }
  });

  const questionMapping = result.reduce((acc, q) => {
    acc = { ...acc, ...getQuestionLabels(q) };
    return acc;
  }, {});

  return questionMapping;
};

export const getQuestionLabels = (q) => {
  const result = {};

  // no question type means we use the default ("TEXT")
  const questionType = q?.type?.toUpperCase() ?? INPUT_TYPES.TEXT;

  if (questionType.toUpperCase() === INPUT_TYPES.LIKERT) {
    q?.objects?.forEach((o) => (result[q.name + '_' + o.name] = o.value));
  } else {
    result[q.name] = q.label;
  }

  return result;
};

// NOTE: we output an array where the first value is the raw value (e.g. used for
// computing averages and sorting) and the second value is the formatted value
export const getQuestionResponseDict = (
  campaign,
  phase,
  category,
  question,
  response,
  outputKey
) => {
  // Used for question types in which we need to map the response ID to the response name
  if (campaign && campaign.phases) {
    const targetPhase = campaign.phases.find(
      (p) => p.type === phase && p[category] && p[category]?.length
    );
    if (targetPhase) {
      const matchedQuestion = targetPhase[category].find(
        (q) => q.name === question
      );

      const matchedQuestionType = matchedQuestion?.type?.toUpperCase();

      if (matchedQuestion) {
        if (
          matchedQuestionType === INPUT_TYPES.DROPDOWN &&
          matchedQuestion.objects
        ) {
          const options = matchedQuestion.objects;
          const matchedOption = options.find((o) => o.id === response);
          if (matchedOption) {
            // array value: first value is raw for sorting, second is display value
            return { [outputKey]: [matchedOption.id, matchedOption.name] };
          }
        }

        if (
          (matchedQuestionType === INPUT_TYPES.MULTIPLE_CHOICE ||
            matchedQuestionType === INPUT_TYPES.SELECT) &&
          matchedQuestion.options
        ) {
          const options = matchedQuestion.options;
          const matchedOption = options.find((o) => o.id === response);
          if (matchedOption) {
            // array value: first value is raw for sorting, second is display value
            return { [outputKey]: [matchedOption.id, matchedOption.name] };
          }
        }

        // Likert questions are a special case, since the response is an object
        // with multiple keys, and we need to map each key to the appropriate
        // response name
        if (matchedQuestionType === INPUT_TYPES.LIKERT) {
          const result = {};
          if (matchedQuestion.objects) {
            // for likert when there is EXACTLY ONE question, we save that specific
            // response instead of an object (so that we can use likert when needed
            // to answer questions like rating and save the same kind of output) so
            // handle that special case appropriately).
            if (
              matchedQuestion.objects?.length === 1 &&
              (response === null || typeof response !== 'object')
            ) {
              // value is likert value (raw) so no need for array
              result[outputKey + '_' + matchedQuestion.objects[0].name] =
                response;
            } else {
              matchedQuestion.objects.forEach((o) => {
                if (response[o.name]) {
                  // value is likert value (raw) so no need for array
                  result[outputKey + '_' + o.name] = response[o.name];
                }
              });
            }
          }
          return result;
        }
      }
    }
  }

  return { [outputKey]: response };
};

export const getQuestionFromCampaign = (
  questionName,
  campaign,
  phase,
  category
) => {
  if (campaign?.phases) {
    const targetPhase = campaign.phases.find(
      (p) => p.type === phase && p[category]?.length
    );
    if (targetPhase) {
      const matchedQuestion = targetPhase[category].find(
        (q) => q.name === questionName
      );
      return matchedQuestion;
    }
  }
  return null;
};

// Used to determine whether we show a user a banner/alert to complete perf
export const showPerfCampaignAlert = (features, campaign, permissions) => {
  if (!performanceFlowForEndUsersIsEnabled(features)) {
    return false;
  }

  // Don't show campaign alert if campaign is closed, or not in action phase,
  if (
    !getPerfCampaignIsOpen(campaign) ||
    !getPerfCampaignIsInActionPhase(campaign)
  ) {
    return false;
  }

  // Check all active phases (in cases of parallel active phases). If person
  // is participating in any phase, we show the alert
  const activePhases = getPerfCampaignOpenActionPhases(campaign);
  for (const phase of activePhases) {
    if (permissions && permissions?.[phase.type]) {
      return true;
    }
  }

  return false;
};

// Returns the latest incomplete active phase
export const currentIncompletePhase = (
  formatMessage,
  campaign,
  permissions,
  completions
) => {
  if (
    !getPerfCampaignIsOpen(campaign) ||
    !getPerfCampaignIsInActionPhase(campaign)
  ) {
    return null;
  }

  // Check all active phases (in cases of parallel active phases).
  const activePhases = getPerfCampaignOpenActionPhases(campaign);
  for (const phase of activePhases) {
    if (
      permissions &&
      permissions?.[phase.type] &&
      completions &&
      !completions?.[phase.type]
    ) {
      const phaseIndex = campaign?.phases?.findIndex(
        (p) => p.type === phase.type
      );
      if (phaseIndex !== -1) {
        return getPhasePrefix(phaseIndex, formatMessage);
      }
    }
  }

  return null;
};

export const getPhaseRomanNumeral = (phaseNumber) => {
  const ROMAN_NUMERALS = [
    'I',
    'II',
    'III',
    'IV',
    'V',
    'VI',
    'VII',
    'VIII',
    'IX',
  ];

  return ROMAN_NUMERALS[phaseNumber];
};

// goes through all phases and gets the start date from the
// phase that has the earliest start date
export const getEarliestPhaseStartDate = (campaignPhases) => {
  let earliestDate: Date | null = null;
  campaignPhases.forEach((phase) => {
    const pahseStartDate = parseDateFromUnicode(phase.start_date, 'MM/dd/yyyy');
    if (!earliestDate || pahseStartDate < earliestDate) {
      earliestDate = pahseStartDate;
    }
  });
  return earliestDate
    ? formatDateWithUnicode(earliestDate, 'MM/dd/yyyy')
    : null;
};

// similar to the above but for latest date
export const getLatestPhaseEndDate = (campaignPhases) => {
  let latestDate: Date | null = null;
  campaignPhases.forEach((phase) => {
    const pahseEndDate = parseDateFromUnicode(phase.end_date, 'MM/dd/yyyy');
    if (!latestDate || pahseEndDate > latestDate) {
      latestDate = pahseEndDate;
    }
  });
  return latestDate ? formatDateWithUnicode(latestDate, 'MM/dd/yyyy') : null;
};

export const getPhaseStartDate = (campaign, phaseType) => {
  const startDate = campaign.phases.find(
    (it) => it.type === phaseType
  )?.start_date;
  return startDate
    ? getDatePart(parseDateFromUnicode(startDate, 'MM/dd/yyyy'))
    : null;
};

export const getFeatureForPhase = (campaign, phaseType, feature) => {
  return (
    campaign?.phases?.find((it) => it.type === phaseType)?.[feature] ?? null
  );
};

export const transformToSuveyTarget = (enrichedRelationship) => {
  return {
    ...enrichedRelationship.to_person,
    surveyResponseConfig: enrichedRelationship.to_survey_response_config,
  };
};

export const toPersonWithSurvey = (person, personSurvey) => {
  return {
    ...person,
    surveyResponseConfig: personSurvey?.configs,
  };
};

export const campaignEditorStartDateChangeObjectivesTimeframeSideEffects = (
  currentObject,
  newValue,
  organizationSettings,
  extraActions = (_current, _next) => {
    // no-op
  }
) => {
  // has the self phase enabled?
  if (currentObject.type === 'S') {
    const existingConfiguration =
      currentObject[PERFORMANCE_FEATURE_COMPLETE_OBJECTIVES_FOR_QUARTER_STEP];
    const calculatedDefault = generateDefaultObjectiveTimeFrame(
      currentObject,
      newValue,
      organizationSettings
    );
    extraActions?.(
      existingConfiguration,
      calculatedDefault.complete_objectives_for_quarter_step
    );
    return calculatedDefault;
  }
  // otherwise, no side effects
  return {};
};

export const campaignEditorStartDateChangePeerFeedbackAssigmentTimeSideEffects =
  (
    currentObject,
    newValue,
    organizationSettings,
    extraActions = (_current, _next) => {
      // no-op
    }
  ) => {
    // has the self phase enabled?
    if (currentObject.type === 'O') {
      const peer_feedback = currentObject[PERFORMANCE_FEATURE_PEER_FEEDBACK];
      const peer_feedback_assignment_time =
        currentObject[PERFORMANCE_FEATURE_PEER_FEEDBACK_ASSIGNMENT_TIME];
      if (peer_feedback && peer_feedback_assignment_time) {
        extraActions?.(peer_feedback_assignment_time, undefined);
        return {
          [PERFORMANCE_FEATURE_PEER_FEEDBACK_ASSIGNMENT_TIME]: undefined,
        };
      }
    }
    // otherwise, no side effects
    return {};
  };

const generateDefaultObjectiveTimeFrame = (
  currentObject,
  newValue,
  organizationSettings
) => {
  const startDate = parseDateFromUnicode(newValue, 'MM/dd/yyyy');

  const enabled =
    currentObject[PERFORMANCE_FEATURE_COMPLETE_OBJECTIVES_FOR_QUARTER_STEP]
      ?.enabled ?? false;

  const existingTimeFrameStart =
    currentObject[PERFORMANCE_FEATURE_COMPLETE_OBJECTIVES_FOR_QUARTER_STEP]
      ?.quarter?.start;

  const type =
    currentObject[PERFORMANCE_FEATURE_COMPLETE_OBJECTIVES_FOR_QUARTER_STEP]
      ?.quarter?.type ?? organizationSettings.objectives_timeframe;

  const startPeriodMMDD = existingTimeFrameStart
    ? formatDateWithUnicode(
        parseDateFromUnicode(existingTimeFrameStart, 'yyyy-MM-dd'),
        'MM-dd'
      )
    : organizationSettings.objectives_timeframe_default_start_date;

  const quarter = findNearestTimeFrame(startDate, type, startPeriodMMDD);

  return {
    [PERFORMANCE_FEATURE_COMPLETE_OBJECTIVES_FOR_QUARTER_STEP]: {
      enabled,
      quarter: {
        ...quarter,
        start: getDatePart(quarter.start),
        end: getDatePart(quarter.end),
      },
    },
  };
};

export const isQuestionLocked = (
  campaign_question_locks: { [key: string]: string[] },
  phase_type: string,
  question_name: string
): boolean => {
  if (phase_type in campaign_question_locks) {
    return campaign_question_locks[phase_type].includes(question_name);
  }
  return false;
};

export const isPhaseLocked = (
  campaign_question_locks: { [key: string]: string[] },
  phase_type: string
): boolean => {
  return !!(
    phase_type in campaign_question_locks &&
    campaign_question_locks[phase_type].length
  );
};

export const getCurrentCampaignCommon = ({
  organizationId,
  currentProxyPerson,
  campaignId,
  urlParams,
  forceBackgroundRefresh,
  callback,
  errorCallback,
}) => {
  ConfirmAPI.getUrlWithCache(
    '/performance/admin/get-current-campaign',
    'performance-admin-get-current-campaign',
    null, // this is too much data, don't cache it
    null,
    {
      organization: organizationId,
      proxy: currentProxyPerson ? currentProxyPerson.email : undefined,
      campaign: campaignId,
      background_refresh: forceBackgroundRefresh,
      ...urlParams,
    },
    callback,
    errorCallback
  );
};

export const fetchCampaignData = ({
  getCurrentCampaignFunc,
  organizationId,
  currentProxyPerson,
  campaignId,
  forceBackgroundRefresh,
  setBackgroundTaskKey,
  setLoadingInitialBackgroundTask,
  callback,
  setErrorMessage,
}) => {
  getCurrentCampaignFunc({
    organizationId,
    currentProxyPerson,
    campaignId,
    forceBackgroundRefresh,
    callback: (data, _error, _hardError, responseHeaders) => {
      if (data) {
        if (forceBackgroundRefresh) {
          const backgroundTaskKeyFromReponse =
            responseHeaders?.['x-background-task-key'];
          if (backgroundTaskKeyFromReponse) {
            setBackgroundTaskKey(backgroundTaskKeyFromReponse);
          }
          setLoadingInitialBackgroundTask(
            !!data.loading_initial_background_task
          );
        }

        callback(data);
      } else {
        if (forceBackgroundRefresh) {
          setLoadingInitialBackgroundTask(false);
        }
        callback(null);
      }
      if (!forceBackgroundRefresh) {
        setBackgroundTaskKey(null);
      }
    },
    errorCallback: (message) => {
      setErrorMessage(message);
    },
  });
};
