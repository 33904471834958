import { FormattedList, FormattedMessage } from 'react-intl';
import {
  Popover,
  PopoverBody,
  PopoverHeader,
  UncontrolledPopover,
} from 'reactstrap';
import PropTypes, { InferProps } from 'prop-types';
import React, {
  FC,
  Fragment,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import Avatar from './Avatar';
import { Link } from 'react-router-dom';

const AvatarGroup: FC<Props> = ({
  maxFaces: maxFacesProp = 5,
  popoverPlacement = 'top',
  hidePopover = false,
  isDemoOrPreviewMode = false,
  ...props
}) => {
  const className = props.className;
  const maxFaces = props.hideAllButFirst ? 1 : maxFacesProp;
  const [ready, setReady] = useState(false);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const externalPopoverRef = useRef();
  const linkRef = useRef();
  const toggle = () => setPopoverOpen(!popoverOpen);
  const avatarClassName = props.avatarClassName
    ? props.avatarClassName
    : props.size
    ? 'avatar-' + props.size
    : 'avatar-sm';

  const people = useMemo(
    // @ts-expect-error
    () => (props.people?.length > 0 ? props.people : []),
    [props.people]
  );

  useEffect(() => {
    if (linkRef.current) {
      setReady(true);
    }
  }, [linkRef]);

  const additionalPeopleLength = useMemo(
    // @ts-expect-error
    () => people.length - maxFaces,
    // @ts-expect-error
    [maxFaces, people.length]
  );

  const shouldLink = !isDemoOrPreviewMode && !props.unlinked;

  const body =
    props.format === 'circle' ? (
      <div
        // @ts-expect-error
        ref={linkRef}
        className={
          (props.size === 'xs' ? 'circular-avatar-xs' : 'circular-avatar') +
          ' circular-avatar-group' +
          (className ? ' ' + className : '')
        }
        data-number-of-thumbnails={
          // @ts-expect-error
          people?.length > 4 ? '4' : people.length.toString()
        }
      >
        {
          // @ts-expect-error
          people.map((p, index) => {
            // @ts-expect-error
            if (people.length === 1) {
              return (
                <Avatar
                  key={index}
                  // @ts-expect-error
                  person={p}
                  // @ts-expect-error
                  size={props.size}
                  // @ts-expect-error
                  style={p.style}
                  // @ts-expect-error
                  onClick={p.onClick}
                  className={avatarClassName}
                  linked={shouldLink}
                  // @ts-expect-error
                  isExternalUrl={props.isExternalUrl}
                  // @ts-expect-error
                  linkToSubPath={props.linkToSubPath}
                  hidePopover={props.popoverContent ? true : false}
                  // @ts-expect-error
                  popoverPlacement={popoverPlacement}
                />
              );
            } else {
              return (
                <Fragment key={index}>
                  <div className="circular-avatar-group-member-wrapper">
                    <img
                      className="circular-avatar-group-member"
                      // @ts-expect-error
                      src={p.avatar}
                      alt=""
                      // @ts-expect-error
                      data-user-initial={p.given_name?.substr(0, 1)}
                    />
                  </div>
                  {!hidePopover &&
                    !props.popoverContent &&
                    ready &&
                    linkRef?.current && (
                      <Popover
                        trigger="hover focus"
                        // @ts-expect-error
                        placement={popoverPlacement}
                        isOpen={popoverOpen}
                        target={linkRef.current}
                        toggle={toggle}
                      >
                        <PopoverBody>
                          <div>
                            <FormattedList
                              value={[
                                // @ts-expect-error
                                ...people.slice(0, 4).map((p, index) =>
                                  !shouldLink ? (
                                    <p key={index} className="fw-bold d-inline">
                                      {
                                        // @ts-expect-error
                                        p.full_name
                                      }
                                    </p>
                                  ) : (
                                    // @ts-expect-error
                                    <Link key={index} to={p.url}>
                                      {
                                        // @ts-expect-error
                                        p.full_name
                                      }
                                    </Link>
                                  )
                                ),
                                // @ts-expect-error
                                people.length === 5 && (
                                  <p key={5} className="d-inline">
                                    <FormattedMessage
                                      id="app.widgets.people.avatar_group.remaining_1_other"
                                      defaultMessage="1 other"
                                    />
                                  </p>
                                ),
                                // @ts-expect-error
                                people.length > 5 && (
                                  <p key={5} className="d-inline">
                                    <FormattedMessage
                                      id="app.widgets.people.avatar_group.remaining_others"
                                      defaultMessage="{remainingOthers} others"
                                      values={{
                                        // @ts-expect-error
                                        remainingOthers: people.length - 4,
                                      }}
                                    />
                                  </p>
                                ),
                              ].filter((p) => !!p)}
                            />
                          </div>
                        </PopoverBody>
                      </Popover>
                    )}
                </Fragment>
              );
            }
          })
        }
      </div>
    ) : (
      <div
        className={
          'avatar-group d-xs-flex' + (className ? ' ' + className : '')
        }
      >
        {props.preContent}
        {/* @ts-expect-error */}
        {people.map((p, index) => {
          if (!p) {
            console.error(
              'Unrecognized person being rendered in AvatarGroup: ' +
                JSON.stringify(people)
            );
            return <Fragment key={index}></Fragment>;
          }

          if (
            // @ts-expect-error
            (props.hideAllButFirst ? 1 : maxFaces + 1) === people.length ||
            // @ts-expect-error
            index < maxFaces
          ) {
            return (
              <Avatar
                key={index}
                // @ts-expect-error
                person={p}
                // @ts-expect-error
                size={props.size}
                // @ts-expect-error
                style={p.style}
                // @ts-expect-error
                onClick={p.onClick}
                className={avatarClassName}
                linked={shouldLink}
                // @ts-expect-error
                isExternalUrl={props.isExternalUrl}
                // @ts-expect-error
                linkToSubPath={props.linkToSubPath}
                hidePopover={props.popoverContent ? true : false}
                // @ts-expect-error
                popoverPlacement={popoverPlacement}
              />
            );
          } else if (index === maxFaces) {
            return (
              <Fragment key={index}>
                {/* @ts-expect-error */}
                <div ref={linkRef} className={'avatar ' + avatarClassName}>
                  <span className="avatar-title rounded-circle">
                    <FormattedMessage
                      id="app.widgets.people.avatar_group.plus_people"
                      defaultMessage="+{numPeople, number}"
                      values={{ numPeople: additionalPeopleLength }}
                      description="Text for additional people in the AvatarGroup"
                    />
                  </span>
                </div>
                {!hidePopover &&
                  !props.popoverContent &&
                  additionalPeopleLength > 0 &&
                  linkRef?.current && (
                    <Popover
                      trigger="hover focus"
                      // @ts-expect-error
                      placement={popoverPlacement}
                      isOpen={popoverOpen}
                      target={linkRef.current}
                      toggle={toggle}
                    >
                      <>
                        {props.additionalPeoplePopover}
                        {!props.additionalPeoplePopover && (
                          <>
                            <PopoverHeader>
                              {props.popoverHeaderPrefix}
                              {!props.popoverHeaderPrefix && (
                                <>
                                  {additionalPeopleLength}{' '}
                                  <FormattedMessage
                                    id="app.widgets.people.avatar_group.more_people"
                                    defaultMessage="{numPeople, plural, one {more person} other {more people}}"
                                    values={{
                                      numPeople: additionalPeopleLength,
                                    }}
                                    description="Text for additional people in the AvatarGroup"
                                  />
                                </>
                              )}
                            </PopoverHeader>
                            <PopoverBody>
                              <div>
                                {/* @ts-expect-error */}
                                {!people.some(
                                  // @ts-expect-error
                                  (person) => person.title === 'Unattributed'
                                ) && (
                                  <FormattedList
                                    // @ts-expect-error
                                    value={people
                                      .slice(maxFaces)
                                      .map((p, extraIndex) =>
                                        !shouldLink ? (
                                          <p
                                            key={index + '-' + extraIndex}
                                            className="fw-bold d-inline"
                                          >
                                            {/* @ts-expect-error */}
                                            {p.full_name}
                                          </p>
                                        ) : (
                                          <Link
                                            key={index + '-' + extraIndex}
                                            // @ts-expect-error
                                            to={p.url}
                                          >
                                            {/* @ts-expect-error */}
                                            {p.full_name}
                                          </Link>
                                        )
                                      )}
                                  />
                                )}
                              </div>
                            </PopoverBody>
                          </>
                        )}
                      </>
                    </Popover>
                  )}
              </Fragment>
            );
          } else {
            return <Fragment key={index}></Fragment>;
          }
        })}
      </div>
    );

  if (!hidePopover && props.popoverContent) {
    return (
      <>
        {/* @ts-expect-error */}
        <div ref={externalPopoverRef}>{body}</div>
        {/* @ts-expect-error */}
        <UncontrolledPopover
          trigger="hover"
          placement={popoverPlacement}
          target={externalPopoverRef}
        >
          {props.popoverContent}
        </UncontrolledPopover>
      </>
    );
  } else {
    return body;
  }
};

type Props = InferProps<typeof AvatarGroup_propTypes>;

const AvatarGroup_propTypes = {
  format: PropTypes.string,
  style: PropTypes.object,
  size: PropTypes.string,
  maxFaces: PropTypes.number,
  className: PropTypes.string,
  avatarClassName: PropTypes.string,
  people: PropTypes.arrayOf(PropTypes.object.isRequired),
  popoverTargetId: PropTypes.string,
  popoverHeader: PropTypes.string,
  unlinked: PropTypes.bool,
  linkToSubPath: PropTypes.string,
  popoverPlacement: PropTypes.string,
  popoverContent: PropTypes.node,
  isExternalUrl: PropTypes.bool,
  preContent: PropTypes.node,
  hideAllButFirst: PropTypes.bool,
  popoverHeaderPrefix: PropTypes.node,
  additionalPeoplePopover: PropTypes.node,
  hidePopover: PropTypes.bool,
  isDemoOrPreviewMode: PropTypes.bool,
};

export default React.memo(AvatarGroup);
