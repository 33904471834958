import {
  ADDITIONAL_FILTERS_KEY,
  FORMAT_AVATAR_GROUP,
  FORMAT_AVATAR_ONLY,
  FORMAT_AVATAR_WITH_TITLE,
  filteredRows,
  getIconForFieldName,
  getSuggestionsForAdditionalFilter,
  itemsForFieldNameAreEqual,
  rowMatchesFilter,
} from './Filters/common';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Row,
} from 'reactstrap';
import {
  DRAG_AND_DROP_ICON,
  DraggableItemTypes,
  getSearchParamsAsObjectOfArrays,
  sortByFieldFunc,
  useDragAndDrop,
} from '../../../utils/util/util';
import FilterablePeopleTableColumnSelectorModal, {
  useFilterableTableColumnSelectorModal,
} from './FilterablePeopleTable/FilterablePeopleTableColumnSelectorModal';
import { FormattedMessage, useIntl } from 'react-intl';
import React, {
  Fragment,
  createRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  getPersonDisplayTitle,
  peopleObjectsAreEqual,
} from '../../../utils/models/Person';
import { isEqual, throttle } from 'lodash';

import Avatar from './Avatar';
import AvatarGroup from './AvatarGroup';
import CSVDownload from './FilterablePeopleTable/CSVDownload';
import CardHeaderTitle from '../Cards/CardHeaderTitle';
//import SelectInput from '../Inputs/SelectInput';
import { ICONS } from '../../../consts/consts';
import Loading from '../Loading';
import PropTypes from 'prop-types';
import ReactTagsInput from '../Inputs/ReactTagsInput';
import UncontrolledPopover from 'components/SafeUncontrolledPopover';
import _uniqueId from 'lodash/uniqueId';
import update from 'immutability-helper';
import { useDeepCompareEffectNoCheck } from 'use-deep-compare-effect';
import { useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

const INITIAL_TABLE_SIZE = 25;
const TABLE_INFINITE_LOAD_INCREMENT = 100;
const TABLE_INFINITE_LOAD_INTERVAL = 100;

export const BREAKDOWN_FIELDS = {
  function: 'Function',
  level_id: 'Level',
  rating: 'Rating',
  department: 'Department',
  business_unit: 'Business unit',
  location: 'Location',
};

const getField = (
  personIsTopLevelObject,
  arrayValuesUsedForFormatting,
  isRowSelectable,
  selectedRowToggleFieldsSet,
  toggleSelectedRowField,
  getUniqueRowId,
  personFields,
  column,
  uniqueIndexIdentifier,
  rowIndex,
  formatMessage
) => {
  const fieldName = column.field;

  const isMulti = column?.multi;
  const field =
    fieldName === 'person' && personIsTopLevelObject
      ? personFields
      : personFields[fieldName];

  // if arrayValuesUsedForFormatting and array of 2 items provided,
  // first item is raw value and second is html value to be displayed
  const value =
    arrayValuesUsedForFormatting && !isMulti && Array.isArray(field)
      ? field[1]
      : field;

  if (typeof value === 'undefined' && !column.renderIfEmpty) {
    if (column.ifEmpty) {
      return column.ifEmpty;
    }

    return null;
  }

  // if format function provided for output, use that
  if (column.format instanceof Function) {
    return column.format(value, uniqueIndexIdentifier, rowIndex, personFields);
  }

  // "person" and "manager" fields have custom output style
  if (fieldName === 'person' || column.format === FORMAT_AVATAR_WITH_TITLE) {
    const person = value;

    if (!person) {
      return <></>;
    }

    const personWithPreferredName = person;
    const shouldDisableRow =
      toggleSelectedRowField &&
      isRowSelectable &&
      !isRowSelectable(personFields);
    return (
      <Row>
        {toggleSelectedRowField && (
          <Col className="col-auto pe-0 checklist">
            <div
              className="form-check align-middle d-inline-block"
              style={{ height: '0.5rem', paddingTop: '0.5rem' }}
            >
              <Input
                className="form-check-input"
                type="checkbox"
                disabled={shouldDisableRow}
                role={shouldDisableRow ? undefined : 'button'}
                checked={selectedRowToggleFieldsSet.has(
                  getUniqueRowId(personFields)
                )}
                onChange={() =>
                  toggleSelectedRowField(getUniqueRowId(personFields))
                }
              />
            </div>
          </Col>
        )}
        <Col
          className={
            (toggleSelectedRowField ? 'ps-1 ' : '') + 'py-0 pe-1 col-auto'
          }
        >
          <Avatar
            className="mt-2 me-2"
            size="xs"
            linked={person.id ? true : false}
            // open externally to avoid interrupting flow
            isExternalUrl={true}
            person={{
              ...person,
              url: person.url,
            }}
          />
        </Col>
        <Col className="p-0">
          <div>
            {person.id ? (
              <a target="_blank" rel="noopener noreferrer" href={person.url}>
                {personWithPreferredName.full_name}
              </a>
            ) : (
              personWithPreferredName.full_name
            )}
          </div>
          <div className="text-muted fw-normal">
            {getPersonDisplayTitle(formatMessage, person)}
          </div>
        </Col>
      </Row>
    );
  } else if (fieldName === 'manager' || column.format === FORMAT_AVATAR_ONLY) {
    const person = value;

    if (!person) {
      return <></>;
    }

    return (
      <Avatar
        linked={true}
        // open externally to avoid interrupting flow
        isExternalUrl={true}
        className="me-2"
        size="xs"
        person={person}
      />
    );
  } else if (column.format === FORMAT_AVATAR_GROUP) {
    const people = value;

    if (!(people?.length > 0)) {
      return <></>;
    }

    return (
      <AvatarGroup
        linked={true}
        // open externally to avoid interrupting flow
        isExternalUrl={true}
        className="me-2"
        size="xs"
        people={people}
      />
    );
  }

  // return field exactly as is
  if (Number.isNaN(value)) {
    return '';
  } else {
    return value;
  }
};

const matchesFilter = (type, s, id) => {
  if (s._index !== type) {
    return false;
  }

  const have_person_info = s._index === 'people' || s.isPerson;
  return have_person_info
    ? s?.object?.email === id || s?.object?.external_id === id
    : s?.name === id;
};

const getFilterSerializedId = (filter) => {
  // use email if it exists, otherwise external id
  if (filter._index === 'people' || filter.isPerson) {
    return filter?.object?.email || filter?.object?.external_id;
  }

  // for everything else, use the string itself (the name)
  return filter?.name;
};

const TableRow = (props) => {
  const { formatMessage } = useIntl();
  const index = props.index;

  const propsCreateRowHoverButton = props.createHoverButton;
  // generate randomized unique id for drag and drop
  const uniqueId = useRef(_uniqueId());

  const [dragAndDropRef, handlerId, drop, isDragging, drag] = useDragAndDrop(
    DraggableItemTypes.TABLE_ROW,
    uniqueId.current,
    index,
    props.moveRow,
    props.rowsAreReorderable
  );

  const output = useMemo(() => {
    return (
      <>
        {props.columnsForVisualDisplay.map((c, colIndex) => (
          <Fragment key={colIndex}>
            {colIndex === 0 && (
              <th scope="row">
                <span
                  role="button"
                  className="d-inline-block hover-child position-absolute text-muted"
                  style={{ left: '-1.35rem' }}
                >
                  {props.rowsAreReorderable &&
                    !isDragging &&
                    DRAG_AND_DROP_ICON}
                </span>
                {getField(
                  props.personIsTopLevelObject,
                  props.arrayValuesUsedForFormatting,
                  props.isRowSelectable,
                  props.selectedRowToggleFieldsSet,
                  props.toggleSelectedRowField,
                  props.getUniqueRowId,
                  props.personFields,
                  c,
                  colIndex + '-' + index,
                  index,
                  formatMessage
                )}
              </th>
            )}
            {colIndex !== 0 && (
              <td
                className={
                  'pe-1 ' +
                  (c.columnClassName
                    ? c.columnClassName
                    : c.className
                    ? c.className
                    : '')
                }
              >
                {getField(
                  props.personIsTopLevelObject,
                  props.arrayValuesUsedForFormatting,
                  props.isRowSelectable,
                  props.selectedRowToggleFieldsSet,
                  props.toggleSelectedRowField,
                  props.getUniqueRowId,
                  props.personFields,
                  c,
                  colIndex + '-' + index,
                  index
                )}
              </td>
            )}
          </Fragment>
        ))}
      </>
    );
  }, [
    props.columnsForVisualDisplay,
    props.rowsAreReorderable,
    props.personIsTopLevelObject,
    props.arrayValuesUsedForFormatting,
    props.isRowSelectable,
    props.selectedRowToggleFieldsSet,
    props.toggleSelectedRowField,
    props.getUniqueRowId,
    props.personFields,
    isDragging,
    index,
    formatMessage,
  ]);

  const component = useMemo(() => {
    if (props.rowsAreReorderable) {
      drag(drop(dragAndDropRef));
    }
    return (
      <>
        <tr ref={dragAndDropRef} data-handler-id={handlerId}>
          {output}
        </tr>
        {props.rowsAreHoverable && (
          <UncontrolledPopover
            delay={{
              show: 25,
              hide: 250,
            }}
            trigger="hover"
            placement="left"
            // we use dragAndDropRef even if rowsAreReorderable is false
            // because we want the popover to be attached to the row
            target={dragAndDropRef}
          >
            {propsCreateRowHoverButton(props.personFields)}
          </UncontrolledPopover>
        )}
      </>
    );
  }, [
    drag,
    drop,
    handlerId,
    dragAndDropRef,
    props.rowsAreHoverable,
    props.rowsAreReorderable,
    propsCreateRowHoverButton,
    props.personFields,
    output,
  ]);

  return component;
};

const personIsTopLevelRowMatchesFilter = (row, filter) => {
  if (filter._index === 'person') {
    return rowMatchesFilter({ ...row, person: row }, filter);
  }

  return rowMatchesFilter(row, filter);
};

const FilterablePeopleTable = (props) => {
  const { formatMessage } = useIntl();
  const [tableLimitMultiplyFactor, setTableLimitMultiplyFactor] = useState(1);
  const [tableLimit, setTableLimit] = useState(
    props.initialTableSize || INITIAL_TABLE_SIZE
  );
  const location = useLocation();
  const { onFiltersChange } = props;

  const [filters, setFilters] = useState(
    () => props.initialFiltersValue ?? { include: [], exclude: [] }
  );
  const { include: includeFilters, exclude: excludeFilters } = filters;

  useDeepCompareEffectNoCheck(() => {
    if (!props.initialFiltersValue) {
      return;
    }
    setFilters(props.initialFiltersValue);
  }, [props.initialFiltersValue]);

  const filtersChangedInternal = useCallback(
    (value) => {
      onFiltersChange(value);
      setFilters(value);
    },
    [onFiltersChange, setFilters]
  );

  // take columns list and make into dict by field name for quick lookups
  const columnsDict = useMemo(() => {
    if (!(props?.columns?.length > 0)) {
      return {};
    }

    return props.columns.reduce((acc, c) => {
      if (!c.hideFromFilters && !c.csvOnly) {
        acc[c.field] = c;
      }
      return acc;
    }, {});
  }, [props.columns]);

  const getItemFromField = useCallback(
    (fieldName, fieldValue) => {
      const f = columnsDict[fieldName];
      // fall back to default icon for given field name if one
      // was not provided explicitly
      const iconName = f.filterIcon ?? getIconForFieldName(fieldName);
      const item = {
        _index: fieldName,
        isPerson: f.isPerson,
        nameTransformerFunction: f.nameTransformerFunction,
        name:
          typeof f.getFilterDisplayValue === 'function'
            ? f.getFilterDisplayValue(fieldValue)
            : fieldValue,
        icon: iconName,
        description: f.filterDescription ? f.filterDescription : f.name,
        object:
          typeof f.getFilterValue === 'function'
            ? f.getFilterValue(fieldValue)
            : fieldValue,
      };

      return item;
    },
    [columnsDict]
  );

  // default sort is reverse chronological by submission date
  const [sortByFieldsOrFuncs, setSortByFieldsOrFuncs] = useState(
    props.defaultSort
  );

  const [sortAtoZ, setSortAtoZ] = useState(props.defaultSortAtoZ);

  const [showFilters, setShowFilters] = useState(
    (location.search.indexOf('filters') !== -1 ||
      location.search.indexOf('exclude') !== -1 ||
      !props.showToggleFilters) &&
      !props.hideFilters
  );
  const toggleFilters = useCallback(
    () => setShowFilters(!showFilters),
    [showFilters]
  );

  const columns = useMemo(
    () => props.columns.map((c) => ({ ...c, columnRef: createRef() })),
    [props.columns]
  );

  const {
    columnsForVisualDisplay,
    columnsForVisualDisplayUnfiltered,
    visibleColumns,
    handleCallbackColumnSelector,
    handleOpenColumnSelector,
    handleToggleColumnSelector,
    showColumnSelector,
  } = useFilterableTableColumnSelectorModal({
    columns,
    defaultUnselectedColumns: props.defaultUnselectedColumns,
    disabled: props.hideColumnSelector,
  });

  const resetTableLimit = () => {
    setTableLimit(INITIAL_TABLE_SIZE);
    setTableLimitMultiplyFactor(1);
  };

  const toggleSortByFieldsOrFuncs = useCallback(
    (fieldsOrSortFuncs) => {
      resetTableLimit();
      if (isEqual(sortByFieldsOrFuncs, fieldsOrSortFuncs)) {
        setSortAtoZ(!sortAtoZ);
      } else {
        setSortAtoZ(true);
        setSortByFieldsOrFuncs(fieldsOrSortFuncs);
      }
    },
    [sortAtoZ, sortByFieldsOrFuncs]
  );

  const filteredRowsMemo = useMemo(() => {
    const rows = props.rows;
    return filteredRows({
      rows,
      includeFilters,
      excludeFilters,
      opts: props.personIsTopLevelObject
        ? { customRowMatchesFilter: personIsTopLevelRowMatchesFilter }
        : undefined,
    });
  }, [
    props.rows,
    props.personIsTopLevelObject,
    includeFilters,
    excludeFilters,
  ]);

  const sortedFilteredRows = useMemo(() => {
    if (sortByFieldsOrFuncs) {
      const output = [...filteredRowsMemo].sort((a, b) =>
        sortByFieldFunc(a, b, sortByFieldsOrFuncs)
      );
      return sortAtoZ ? output : [...output].reverse();
    } else {
      return filteredRowsMemo;
    }
  }, [filteredRowsMemo, sortAtoZ, sortByFieldsOrFuncs]);

  const tablePeople = useMemo(() => sortedFilteredRows, [sortedFilteredRows]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleScroll = useCallback(
    throttle(() => {
      const peopleTable = document.getElementById('people-table');
      if (!peopleTable || tableLimit >= tablePeople.length) {
        return;
      }
      // ensure that the bottom is always about a full screen height away
      // for cleaner scrolling and never seeing the bottom
      const atBottom =
        window.innerHeight * 2 + window.scrollY >= document.body.offsetHeight;
      const rect = peopleTable.getBoundingClientRect();
      if (atBottom || rect.bottom <= 100) {
        setTableLimit(
          tableLimit + TABLE_INFINITE_LOAD_INCREMENT * tableLimitMultiplyFactor
        );
        // max loads exponentially load more to make the
        // of someone wanting to scroll to the bottom feel
        // less frustrating while still benefitting from quick
        // loads on filters and reloads
        setTableLimitMultiplyFactor(tableLimitMultiplyFactor * 2);
      }
    }, TABLE_INFINITE_LOAD_INTERVAL),
    [tableLimit, tablePeople.length]
  );

  window.onscroll = handleScroll;

  /*let filterList = ['function', 'department'];

  const filterDropdowns = (
    <Row>
      {filterList.map((filter, index) => {
        const name = filter;
        const selectText = BREAKDOWN_FIELDS[filter] + '...';
        return (
          <Col key={index} className="pt-1">
            <SelectInput
              className="w-100"
              clearable={true}
              name={name}
              placeholder={selectText}
              defaultOptions={true}
              multiple={true}
            />
          </Col>
        );
      })}
    </Row>
  );*/

  const suggestions = useMemo(() => {
    if (!(props.rows?.length > 0)) {
      return [];
    }

    // initialize list of fields as single arrays
    // NOTE: order of columns is what takes precedence
    // in autocomplete dropdown when options are limited
    const valuesByColumnField = props.columns.reduce((colAcc, c) => {
      if (!c.hideFromFilters && !c.csvOnly) {
        colAcc[c.field] = [];
      }
      return colAcc;
    }, {});

    const additionalFiltersKeys = new Set();
    let additionalFilterItems = [];

    const suggestionLists = props.rows.reduce((acc, row) => {
      // add top-level person to filter list manually
      // if person is top level object
      if (props.personIsTopLevelObject) {
        acc['person'].push(row);
      }

      row?.[ADDITIONAL_FILTERS_KEY]?.forEach((filterItem) => {
        if (!additionalFiltersKeys.has(filterItem.key)) {
          additionalFiltersKeys.add(filterItem.key);
          additionalFilterItems = additionalFilterItems.concat(
            getSuggestionsForAdditionalFilter(
              filterItem.key,
              filterItem.descriptor,
              row[filterItem.key]
            )
          );
        }
      });

      for (let key in acc) {
        if (
          row[key] &&
          acc[key].findIndex((v) =>
            itemsForFieldNameAreEqual(key, v, row[key], columnsDict[key])
          ) === -1
        ) {
          acc[key].push(row[key]);
        }
      }

      return acc;
    }, valuesByColumnField);

    let newSuggestions = [];
    for (let i in suggestionLists) {
      newSuggestions = newSuggestions.concat(
        suggestionLists[i].map((s) => getItemFromField(i, s))
      );
    }

    return newSuggestions.concat(additionalFilterItems);
  }, [
    props.rows,
    props.columns,
    props.personIsTopLevelObject,
    columnsDict,
    getItemFromField,
  ]);

  const getFilterByAbbreviationAndType = useCallback(
    (filterType, filterId) => {
      return suggestions.find((s) => matchesFilter(filterType, s, filterId));
    },
    [suggestions]
  );

  const getFilterFromSerialization = useCallback(
    (str) => {
      if (str.indexOf('-') === -1) {
        return null;
      }

      // first character is filter type abbreviation
      const filterTypeAbbreviation = str.slice(0, str.indexOf('-'));
      // second char is -, then remaining characters are id for filter
      const filterId = str.slice(str.indexOf('-') + 1);
      return getFilterByAbbreviationAndType(filterTypeAbbreviation, filterId);
    },
    [getFilterByAbbreviationAndType]
  );

  const getFiltersFromSerialization = useCallback(
    (str) => {
      return str
        .split(',')
        .map(getFilterFromSerialization)
        .filter((f) => f);
    },
    [getFilterFromSerialization]
  );

  const serializeFilter = useCallback((f) => {
    if (!f) {
      return null;
    }

    // for manager and manager_or_above, get external_id, or id (whatever is available)
    const filterTypeAbbreviation = f._index;
    const filterId = getFilterSerializedId(f);

    return filterTypeAbbreviation + '-' + filterId;
  }, []);

  const serializeFilters = useCallback(
    (fs) => {
      return fs.map(serializeFilter).join(',');
    },
    [serializeFilter]
  );

  const [searchParams, setSearchParams] = useState(() => {
    return new URLSearchParams(window.location.search);
  });

  useEffect(() => {
    const newIncludeFilters = searchParams
      .getAll('filters[]')
      .map(getFiltersFromSerialization)
      .flat();

    const newExcludeFilters = searchParams
      .getAll('exclude[]')
      .map(getFiltersFromSerialization)
      .flat();

    const newFilters = {
      include: newIncludeFilters,
      exclude: newExcludeFilters,
    };

    // if filters serialize to the same strings, treat them as identical
    if (
      serializeFilters(newFilters.include) ===
        serializeFilters(filters.include) &&
      serializeFilters(newFilters.exclude) === serializeFilters(filters.exclude)
    ) {
      // no changes, no action needed (this is necessary to prevent infinite recursion when
      // using bookmarkUrl=true from an outside component that may have a constantly changing onChange)
      return;
    }

    // only change these variables if the url changes
    if (newIncludeFilters?.length > 0 || newExcludeFilters?.length > 0) {
      resetTableLimit();
      filtersChangedInternal(newFilters);
    }
  }, [
    filters,
    filtersChangedInternal,
    getFiltersFromSerialization,
    searchParams,
    serializeFilters,
  ]);

  const setFiltersAndUpdateUrl = useCallback(
    (includeTags, excludeTags) => {
      filtersChangedInternal({ include: includeTags, exclude: excludeTags });

      if (!props.bookmarkUrl) {
        return;
      }
      const currentQueryParameters = getSearchParamsAsObjectOfArrays(
        new URLSearchParams(location.search)
      );

      delete currentQueryParameters?.['filters'];
      delete currentQueryParameters?.['exclude'];

      const newQueryParameters = {
        'filters[]': includeTags.map((x) => serializeFilter(x)),
        'exclude[]': excludeTags.map((x) => serializeFilter(x)),
      };
      const newQueryString = new URLSearchParams(
        Object.assign(currentQueryParameters, newQueryParameters)
      ).toString();

      window.history.replaceState(
        null,
        null,
        `${location.pathname}?${newQueryString}`
      );
      setSearchParams(new URLSearchParams(newQueryString));
      resetTableLimit();
    },
    [
      location.pathname,
      location.search,
      serializeFilter,
      props.bookmarkUrl,
      filtersChangedInternal,
    ]
  );

  const tagsAreEqual = useCallback((a, b) => {
    if (a?._index !== b?._index) {
      return false;
    }

    if (a._index === 'people' || a.isPerson) {
      return peopleObjectsAreEqual(a, b);
    }

    return a === b;
  }, []);

  const includeFilterInput = useMemo(() => {
    const placeholder =
      props.includeFilterPlaceholder ||
      formatMessage({
        id: 'app.widgets.people.include_filter_placeholder',
        defaultMessage: 'Filter by name or anything else',
      });

    return (
      <Col className={props.customStyles.includeClassName}>
        <ReactTagsInput
          readOnly={props.disabled}
          tagsAreEqual={tagsAreEqual}
          value={includeFilters}
          allowNew={false}
          suggestions={suggestions}
          placeholder={placeholder}
          callback={(tags) => setFiltersAndUpdateUrl(tags, excludeFilters)}
          useTagCards={true}
          disableLengthCheck={true}
        />
      </Col>
    );
  }, [
    excludeFilters,
    includeFilters,
    setFiltersAndUpdateUrl,
    suggestions,
    tagsAreEqual,
    props.customStyles,
    props.includeFilterPlaceholder,
    formatMessage,
    props.disabled,
  ]);

  const excludeFilterInput = useMemo(() => {
    const placeholder =
      props.excludeFilterPlaceholder ||
      formatMessage({
        id: 'app.widgets.people.exclude_filter_placeholder',
        defaultMessage: 'Exclude anything',
      });

    return (
      <Col className={props.customStyles.excludeClassName}>
        <ReactTagsInput
          readOnly={props.disabled}
          className={props.excludeFiltersClassName}
          inputClassName={props.inputClassName}
          tagsAreEqual={tagsAreEqual}
          value={excludeFilters}
          allowNew={false}
          suggestions={suggestions}
          placeholder={placeholder}
          callback={(tags) => setFiltersAndUpdateUrl(includeFilters, tags)}
          useTagCards={true}
        />
      </Col>
    );
  }, [
    excludeFilters,
    includeFilters,
    props.excludeFiltersClassName,
    props.inputClassName,
    props.customStyles,
    setFiltersAndUpdateUrl,
    suggestions,
    tagsAreEqual,
    props.excludeFilterPlaceholder,
    formatMessage,
    props.disabled,
  ]);

  const csvHeaders = useMemo(
    () =>
      columns
        .filter((c) => !c.hideFromCSV && !c.filterOnly)
        .reduce((acc, c) => {
          // allow for splitting a given value into multiple columns
          if (Array.isArray(c.csvName)) {
            return [
              ...acc,
              ...c.csvName.map((name) => ({ label: name, key: name })),
            ];
          } else {
            return [
              ...acc,
              { label: c.csvName ? c.csvName : c.name, key: c.field },
            ];
          }
        }, []),
    [columns]
  );

  const exportButton = useMemo(
    () =>
      !props.hideExportButton && tablePeople?.length ? (
        <Col className="col-auto pe-0">
          <CSVDownload
            data={tablePeople}
            columns={columns}
            headers={csvHeaders}
            exportActionTextFunction={props.exportActionTextFunction}
            personIsTopLevelObject={props.personIsTopLevelObject}
          />
        </Col>
      ) : (
        <></>
      ),
    [
      columns,
      csvHeaders,
      props.exportActionTextFunction,
      props.hideExportButton,
      props.personIsTopLevelObject,
      tablePeople,
    ]
  );

  const propsSelectedRowToggleFieldsSet = props.selectedRowToggleFieldsSet;
  const propsSetSelectedRowToggleFieldsSet =
    props.setSelectedRowToggleFieldsSet;
  const propsGetUniqueRowId = props.getUniqueRowId;
  const propsIsRowSelectable = props.isRowSelectable;

  const atLeastOneRowIsSelectable = useMemo(() => {
    return (
      propsIsRowSelectable && sortedFilteredRows?.some(propsIsRowSelectable)
    );
  }, [sortedFilteredRows, propsIsRowSelectable]);

  const allSelectableRowsAreSelected = useMemo(() => {
    if (!propsIsRowSelectable) {
      return false;
    }

    const selectableFilteredRows =
      sortedFilteredRows?.filter(propsIsRowSelectable);

    // if every row is selected, return true, else false
    return (
      selectableFilteredRows?.length > 0 &&
      selectableFilteredRows?.every((row) =>
        propsSelectedRowToggleFieldsSet?.has(propsGetUniqueRowId(row))
      )
    );
  }, [
    sortedFilteredRows,
    propsIsRowSelectable,
    propsSelectedRowToggleFieldsSet,
    propsGetUniqueRowId,
  ]);

  const toggleAllSelectableRows = useCallback(() => {
    if (allSelectableRowsAreSelected) {
      // deselect all
      propsSetSelectedRowToggleFieldsSet(new Set());
    } else {
      // select all
      propsSetSelectedRowToggleFieldsSet(
        new Set(
          sortedFilteredRows
            ?.filter(propsIsRowSelectable)
            .map(propsGetUniqueRowId)
        )
      );
    }
  }, [
    allSelectableRowsAreSelected,
    propsSetSelectedRowToggleFieldsSet,
    sortedFilteredRows,
    propsIsRowSelectable,
    propsGetUniqueRowId,
  ]);

  // for drag-and-drop (NOTE: we can't allow filtering/sorting for tables
  // that allow drag-and-drop)
  const propsOnChange = props.onChange;
  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      // we use props.rawReorderableRows instead of props.rows because the latter
      // is for visual display and the former is the actual raw data
      const newRows = update(props.rawReorderableRows, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, props.rawReorderableRows[dragIndex]],
        ],
      });

      propsOnChange(newRows);
    },
    [propsOnChange, props.rawReorderableRows]
  );

  const output = useMemo(
    () => (
      <>
        <Row>
          <Col>
            <Card className={props.className}>
              {!props.hideHeader &&
                (props.title ||
                  props.actions ||
                  !props.hideFilters ||
                  !props.hideExportButton ||
                  props.headerContent) &&
                props.showToggleFilters && (
                  <CardHeader>
                    {props.title && (
                      <CardHeaderTitle>{props.title}</CardHeaderTitle>
                    )}
                    <Row className="justify-content-between">
                      {!props.hideFilters && (
                        <Col className="col-auto ps-0">
                          <Button
                            disabled={props.disabled}
                            color="light"
                            className="btn-sm"
                            onClick={toggleFilters}
                          >
                            <i
                              className="fe fe-filter"
                              style={{ position: 'relative', top: '1px' }}
                            />{' '}
                            {showFilters ? (
                              <FormattedMessage
                                id="app.widgets.task.hide_filters_button_text"
                                defaultMessage="Hide filters"
                              />
                            ) : (
                              <FormattedMessage
                                id="app.widgets.task.toggle_filters_button_text"
                                defaultMessage="Filter this list"
                              />
                            )}
                          </Button>
                        </Col>
                      )}
                      {!props.hideColumnSelector && (
                        <Col className="col-auto ps-0">
                          <Button
                            disabled={props.disabled}
                            color="light"
                            className="btn-sm"
                            onClick={handleOpenColumnSelector}
                          >
                            <i
                              className={`${ICONS.CHECK_OPTIONS} me-2`}
                              style={{ position: 'relative', top: '1px' }}
                            />
                            <FormattedMessage
                              id="app.views.widgets.people.filterable_people_table.button.fields_selector.text"
                              defaultMessage="Select fields to display"
                            />
                          </Button>
                          <FilterablePeopleTableColumnSelectorModal
                            modalIsOpen={showColumnSelector}
                            columnDescriptors={
                              columnsForVisualDisplayUnfiltered
                            }
                            onToggle={handleToggleColumnSelector}
                            onCallback={handleCallbackColumnSelector}
                            selectedColumns={visibleColumns}
                            defaultUnselectedColumns={
                              props.defaultUnselectedColumns
                            }
                          />
                        </Col>
                      )}
                      {props.headerContent && <Col>{props.headerContent}</Col>}
                      {props.actions} {!props.hideExportButton && exportButton}
                    </Row>
                  </CardHeader>
                )}
              {showFilters && props.filtersVerticalDisplay && (
                <div>
                  <Row className="align-items-center">
                    <Col className="col-auto ps-3">
                      <i className="fe fe-filter d-flex justify-content-center" />
                    </Col>
                    {includeFilterInput}
                  </Row>
                  <Row className="mt-3 align-items-center">
                    <Col className="col-auto ps-3">
                      <i className="fe fe-user-x d-flex justify-content-center" />
                    </Col>
                    {excludeFilterInput}
                  </Row>
                </div>
              )}
              {showFilters && !props.filtersVerticalDisplay && (
                <CardBody className="py-3">
                  <Row>
                    {includeFilterInput}
                    {excludeFilterInput}
                  </Row>
                </CardBody>
              )}
              {props.children}
              {props.showTable && tablePeople.length === 0 && (
                <div className="text-center my-4">
                  <span className="text-muted">
                    <FormattedMessage
                      id="app.widgets.task.no_results_found"
                      defaultMessage="No results found"
                    />
                  </span>
                </div>
              )}
              {props.showTable && tablePeople.length > 0 && (
                <div
                  className="table-responsive"
                  data-list='{"valueNames": ["tables-row", "tables-first", "tables-last", "tables-handle"]}'
                >
                  <table
                    id="people-table"
                    className={
                      'card-table table table-sm mb-0' +
                      (props.tableClassName ? ' ' + props.tableClassName : '')
                    }
                  >
                    <thead>
                      <tr>
                        {columnsForVisualDisplay.map((c, index) => {
                          // use UUID to ensure not clashing unique key
                          // for the popover content
                          const uuid = uuidv4();
                          // note: default is sortable; we need the column to be EXPLICITLY
                          // default as not sortable to disable sorting on it
                          const columnIsSortable = c.sortable !== false;

                          return (
                            <th
                              key={index}
                              scope="col"
                              role={columnIsSortable ? 'button' : undefined}
                              style={{
                                // default text in header to not wrap
                                whiteSpace: 'nowrap',
                                ...(c.style ?? {}),
                              }}
                              onClick={
                                columnIsSortable
                                  ? () =>
                                      toggleSortByFieldsOrFuncs(
                                        c.sort ? [c.sort] : [c.field]
                                      )
                                  : undefined
                              }
                              className={
                                'pe-1 ' +
                                // we use columnClassName OR className because
                                // the latter is more common, but when using
                                // a TableEditor, className is used for the
                                // input field, so we use columnClassName so
                                // it only affects the layout here and NOT
                                // the input field's class
                                (c.columnClassName
                                  ? c.columnClassName
                                  : c.className
                                  ? c.className
                                  : '')
                              }
                            >
                              <div
                                id={'column-' + uuid}
                                ref={c.columnRef}
                                className={
                                  columnIsSortable
                                    ? `text-muted list-sort`
                                    : 'text-muted'
                                }
                                data-sort={
                                  columnIsSortable ? 'tables-row' : undefined
                                }
                              >
                                {props.toggleSelectedRowField && index === 0 && (
                                  <div
                                    className="d-inline-block pe-3"
                                    onClick={(e) => {
                                      // don't propagate click since it will trigger the table header click
                                      // which will cause the table to scroll to the top
                                      e.stopPropagation();
                                    }}
                                  >
                                    <Input
                                      className="form-check-input"
                                      type="checkbox"
                                      disabled={!atLeastOneRowIsSelectable}
                                      role={
                                        atLeastOneRowIsSelectable
                                          ? 'button'
                                          : undefined
                                      }
                                      checked={allSelectableRowsAreSelected}
                                      onChange={toggleAllSelectableRows}
                                    />
                                  </div>
                                )}
                                <div
                                  className={
                                    'd-inline-block align-middle' +
                                    (c.showFullLengthHeader
                                      ? ''
                                      : ' text-truncate')
                                  }
                                  style={
                                    c.showFullLengthHeader
                                      ? {}
                                      : { maxWidth: '8rem' }
                                  }
                                >
                                  {c.name === 'Person' ? (
                                    <FormattedMessage
                                      id="app.widgets.task.table_people"
                                      defaultMessage="{count, number} people"
                                      values={{ count: tablePeople?.length }}
                                      description="Table people count"
                                    />
                                  ) : (
                                    c.name
                                  )}
                                </div>
                              </div>
                              {c.popoverContent && (
                                <UncontrolledPopover
                                  placement="top"
                                  trigger="hover"
                                  target={c.columnRef}
                                >
                                  {c.popoverContent}
                                </UncontrolledPopover>
                              )}
                              {/* mainly for truncated custom columns, e.g. promo packets dashboard,
                                  to display the full text of the columns */}
                              {!c.popoverContent && !c.showFullLengthHeader && (
                                <UncontrolledPopover
                                  placement="top"
                                  trigger="hover"
                                  target={c.columnRef}
                                >
                                  {c.name}
                                </UncontrolledPopover>
                              )}
                            </th>
                          );
                        })}
                      </tr>
                    </thead>
                    <tbody className="list">
                      {tablePeople.slice(0, tableLimit).map((row, index) => (
                        <TableRow
                          key={propsGetUniqueRowId(row)}
                          index={index}
                          moveRow={moveRow}
                          columnsForVisualDisplay={columnsForVisualDisplay}
                          personIsTopLevelObject={props.personIsTopLevelObject}
                          personFields={row}
                          arrayValuesUsedForFormatting={
                            props.arrayValuesUsedForFormatting
                          }
                          isRowSelectable={props.isRowSelectable}
                          selectedRowToggleFieldsSet={
                            props.selectedRowToggleFieldsSet
                          }
                          toggleSelectedRowField={props.toggleSelectedRowField}
                          getUniqueRowId={propsGetUniqueRowId}
                          rowsAreReorderable={props.rowsAreReorderable}
                          rawReorderableRow={props.rawReorderableRows?.[index]}
                          rowsAreHoverable={props.rowsAreHoverable}
                          createHoverButton={props.createHoverButton}
                        />
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </Card>
          </Col>
        </Row>
        {props.showTable && tableLimit < tablePeople?.length && <Loading />}
      </>
    ),
    [
      props.className,
      props.hideHeader,
      props.title,
      props.actions,
      props.hideFilters,
      props.hideExportButton,
      props.headerContent,
      props.showToggleFilters,
      props.disabled,
      props.hideColumnSelector,
      props.defaultUnselectedColumns,
      props.filtersVerticalDisplay,
      props.children,
      props.showTable,
      props.tableClassName,
      props.toggleSelectedRowField,
      props.personIsTopLevelObject,
      props.arrayValuesUsedForFormatting,
      props.isRowSelectable,
      props.selectedRowToggleFieldsSet,
      props.rowsAreReorderable,
      props.rawReorderableRows,
      props.rowsAreHoverable,
      props.createHoverButton,
      toggleFilters,
      showFilters,
      handleOpenColumnSelector,
      showColumnSelector,
      columnsForVisualDisplayUnfiltered,
      handleToggleColumnSelector,
      handleCallbackColumnSelector,
      visibleColumns,
      exportButton,
      includeFilterInput,
      excludeFilterInput,
      tablePeople,
      columnsForVisualDisplay,
      tableLimit,
      atLeastOneRowIsSelectable,
      allSelectableRowsAreSelected,
      toggleAllSelectableRows,
      toggleSortByFieldsOrFuncs,
      propsGetUniqueRowId,
      moveRow,
    ]
  );

  return output;
};

FilterablePeopleTable.defaultProps = {
  personIsTopLevelObject: false,
  disabled: false,
  defaultSort: [],
  defaultSortAtoZ: true,
  showTable: true,
  showToggleFilters: true,
  filtersVerticalDisplay: false,
  initialFiltersValue: { include: [], exclude: [] },
  customStyles: {
    includeClassName: 'ps-0',
    excludeClassName: 'col-6 pe-0',
  },
  hideHeader: false,
  hideColumnSelector: true,
  bookmarkUrl: true,
  getUniqueRowId: (row) => row.id,
  onFiltersChange: () => {
    /* DO NOTHING */
  },
  defaultUnselectedColumns: [],
};

FilterablePeopleTable.propTypes = {
  personIsTopLevelObject: PropTypes.bool,
  disabled: PropTypes.bool,
  arrayValuesUsedForFormatting: PropTypes.bool,
  title: PropTypes.string,
  actions: PropTypes.node,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  defaultSort: PropTypes.arrayOf(PropTypes.string),
  defaultSortAtoZ: PropTypes.bool,
  selectedRowToggleFieldsSet: PropTypes.object,
  setSelectedRowToggleFieldsSet: PropTypes.func,
  getUniqueRowId: PropTypes.func.isRequired,
  toggleSelectedRowField: PropTypes.func,
  isRowSelectable: PropTypes.func,
  tableClassName: PropTypes.string,
  hideFilters: PropTypes.bool,
  hideColumnSelector: PropTypes.bool,
  initialFiltersValue: PropTypes.object,
  includeFilterPlaceholder: PropTypes.string,
  excludeFilterPlaceholder: PropTypes.string,
  showTable: PropTypes.bool,
  showToggleFilters: PropTypes.bool,
  filtersVerticalDisplay: PropTypes.bool,
  customStyles: PropTypes.shape({
    includeClassName: PropTypes.string,
    excludeClassName: PropTypes.string,
  }),
  bookmarkUrl: PropTypes.bool,
  hideExportButton: PropTypes.bool,
  className: PropTypes.string,
  excludeFiltersClassName: PropTypes.string,
  rowsAreReorderable: PropTypes.bool,
  rawReorderableRows: PropTypes.arrayOf(PropTypes.object),
  // for row reordering
  onChange: PropTypes.func,
  onFiltersChange: PropTypes.func,
  rowsAreHoverable: PropTypes.bool,
  createHoverButton: PropTypes.func,
  exportActionTextFunction: PropTypes.func,
  headerContent: PropTypes.node,
  hideHeader: PropTypes.bool,
  initialTableSize: PropTypes.number,
  defaultUnselectedColumns: PropTypes.arrayOf(PropTypes.string),
};

export default React.memo(FilterablePeopleTable);
