import * as consts from '../../../consts/consts';

import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory, useLocation, withRouter } from 'react-router';
import { Card, CardBody } from 'reactstrap';
import {
  CAMPAIGN_STATUSES,
  getPhaseByType,
  getRelevantRelationshipsForMultipleManagers,
} from '../../../utils/models/Campaign';
import {
  PERFORMANCE_FEATURE_UPWARD_MANAGER_OPEN_RESPONSE_QUESTIONS,
  PHASE_TYPE_OTHERS,
  getCampaignFeature,
  getCampaignHasFeatureEnabled,
  getCurrentPerformancePreviewPathPrefix,
  getManagersEligibleForUpwardFeedback,
  getPhaseOpenResponseQuestions,
  perfCampaignCallback,
  updateLatestPerfStep,
  useCurrentStepNumber,
} from '../../../utils/models/Performance';
import {
  getPersonDisplayTitle,
  peopleObjectsAreEqual,
} from '../../../utils/models/Person';

import { connect } from 'react-redux';
import { type RouteComponentProps } from 'react-router-dom';
import { Campaign, ReduxState } from 'types';
import { setCurrentPerfSurveyResponse } from '../../../actions';
import { relationshipIsCompleted } from '../../../utils/models/Relationship';
import { RELATIONSHIP_TYPES } from '../../../utils/models/RelationshipUtils';
import { applyCustomFiltersToQuestions } from '../../Widgets/People/Filters/common';
import PerformancePage from '../PerformancePage';
import PerformancePersonAssessment from '../PerformancePersonAssessment';
import PerformanceStepAssessManagerListNonEligible from './PerformanceStepAssessManagerListNonEligible';
import PerformanceStepAssessManagerListProceedButton from './PerformanceStepAssessManagerListProceedButton';
import PerformanceStepAssessManagerListTable from './PerformanceStepAssessManagerListTable';

interface Props
  extends RouteComponentProps,
    Pick<
      ReduxState,
      'me' | 'currentOrganization' | 'currentPerfSurveyResponse' | 'demoPeople'
    > {
  setCurrentPerfSurveyResponse: (survey: any) => void;
  campaign: Campaign;
  setCampaign: (campaign: Campaign) => void;
}

const PerformanceStepAssessManagerList: FC<Props> = (props) => {
  const { formatMessage } = useIntl();
  const location = useLocation();
  const history = useHistory();

  const campaign = props.campaign;
  const setCampaign = props.setCampaign;
  const propsSetCurrentPerfSurveyResponse = props.setCurrentPerfSurveyResponse;
  const propsDemoPeople = props.demoPeople;

  // for sorting, true means ascending, false means descending, undefined means don't sort
  const [sortByName, setSortByName] = useState<boolean | undefined>(true);

  const toggleSortByName = useCallback(() => {
    setSortByName(!sortByName);
  }, [sortByName]);

  const currentStepNumber = useCurrentStepNumber(campaign);

  const relevantRelationships = useMemo(
    () => getRelevantRelationshipsForMultipleManagers(campaign),
    [campaign]
  );

  // NOTE: we should never sort this inline as the
  // order of these direct reports is use to determine
  // which person you are reviewing when you click to
  // review
  const defaultSortedManagers = useMemo(
    () =>
      getManagersEligibleForUpwardFeedback(
        props.me,
        campaign,
        // @ts-expect-error
        campaign.relationships,
        props.demoPeople
      ),
    [campaign, props.demoPeople, props.me]
  );

  // This index is the 0 based number in the (sorted?)
  // list of direct reports the manager needs to evaluate.
  // A value from 0+ means we are in the detail page,
  // undefined means we are in list view.
  const currentManagerIndex = useMemo(
    () =>
      new URLSearchParams(location.search).get('index')
        ? // @ts-expect-error
          parseInt(new URLSearchParams(location.search).get('index'))
        : undefined,
    [location.search]
  );

  const currentManager = useMemo(
    () =>
      typeof currentManagerIndex !== 'undefined'
        ? defaultSortedManagers[currentManagerIndex]
        : undefined,
    [currentManagerIndex, defaultSortedManagers]
  );

  const sortedManagers = useMemo(
    () =>
      // NOTE: we MUST use [... here to deep copy so the
      // original order persists, otherwise when sorting the
      // buttons will go to the wrong person as we use the index
      // id based on the default order
      [...defaultSortedManagers].sort((a, b) => {
        if (typeof sortByName !== 'undefined') {
          return sortByName
            ? a.full_name.localeCompare(b.full_name)
            : b.full_name.localeCompare(a.full_name);
        }
      }),
    [defaultSortedManagers, sortByName]
  );

  useEffect(() => {
    // scroll to top so we see everything intended (in case we scrolled below before)
    window.scrollTo(0, 0);
  }, [currentManager]);

  const sortedManagersNondatasetRelationships = useMemo(
    () =>
      sortedManagers.map((dr) => {
        return relevantRelationships?.find(
          (r) =>
            !r.dataset &&
            peopleObjectsAreEqual(r.from_person, props.me) &&
            peopleObjectsAreEqual(r.to_person, dr)
        );
      }),
    [sortedManagers, relevantRelationships, props.me]
  );

  const managerUpwardFeedbackComplete = useCallback(
    (r) => {
      return relationshipIsCompleted(r, campaign);
    },
    [campaign]
  );

  const managersRemaining = useMemo(
    () =>
      sortedManagersNondatasetRelationships.filter(
        (r) => !managerUpwardFeedbackComplete(r)
      )?.length,
    [sortedManagersNondatasetRelationships, managerUpwardFeedbackComplete]
  );

  const previewPathPrefix = getCurrentPerformancePreviewPathPrefix();

  const callback = useCallback(
    (data) => {
      if (data) {
        // when done with all direct reports, go to next step
        if (managersRemaining === 0) {
          propsSetCurrentPerfSurveyResponse(
            updateLatestPerfStep(
              props.currentPerfSurveyResponse,
              currentStepNumber
            )
          );

          perfCampaignCallback(
            props.me,
            props.currentOrganization,
            campaign,
            history,
            propsDemoPeople,
            data,
            formatMessage
          );
        } else {
          history.push(
            previewPathPrefix +
              consts.PERFORMANCE_STEP_ASSESS_MANAGER(formatMessage).path
          );
        }
      }
    },
    [
      managersRemaining,
      propsSetCurrentPerfSurveyResponse,
      props.currentPerfSurveyResponse,
      props.me,
      props.currentOrganization,
      currentStepNumber,
      campaign,
      history,
      propsDemoPeople,
      previewPathPrefix,
      formatMessage,
    ]
  );

  const setCurrentManager = useCallback(
    (p) => {
      const defaultSortPersonIndex = defaultSortedManagers.findIndex((p2) =>
        peopleObjectsAreEqual(p, p2)
      );

      history.push(
        previewPathPrefix +
          consts.PERFORMANCE_STEP_ASSESS_MANAGER(formatMessage).path +
          '?index=' +
          defaultSortPersonIndex
      );
    },
    [defaultSortedManagers, formatMessage, history, previewPathPrefix]
  );

  const object = useMemo(
    () => ({
      id: props.currentPerfSurveyResponse
        ? props.currentPerfSurveyResponse.id
        : undefined,

      campaign: campaign.id,
      step: currentStepNumber,
      responses: {},
    }),

    [currentStepNumber, campaign.id, props.currentPerfSurveyResponse]
  );
  const isDemoOrPreviewMode = useMemo(
    // @ts-expect-error
    () => campaign?.status === CAMPAIGN_STATUSES.DEMO,
    [campaign]
  );

  const unfilteredFreeResponseQuestions = useMemo(() => {
    const hasCustomQuestions = getCampaignHasFeatureEnabled(
      campaign,
      PERFORMANCE_FEATURE_UPWARD_MANAGER_OPEN_RESPONSE_QUESTIONS
    );

    if (hasCustomQuestions) {
      return getCampaignFeature(
        campaign,
        PERFORMANCE_FEATURE_UPWARD_MANAGER_OPEN_RESPONSE_QUESTIONS
      );
    }
    // @ts-expect-error
    return getPhaseOpenResponseQuestions(
      getPhaseByType(campaign, PHASE_TYPE_OTHERS)
    );
  }, [campaign]);

  const freeResponseQuestions = useMemo(
    () =>
      applyCustomFiltersToQuestions({
        questions: unfilteredFreeResponseQuestions,
        campaign,
        targetPerson: currentManager,
      }),
    [unfilteredFreeResponseQuestions, campaign, currentManager]
  );

  const isEligibleToGiveUpwardFeedback =
    props.currentPerfSurveyResponse?.configs
      ?.is_writing_upward_manager_review !== false;

  return (
    <PerformancePage
      campaign={campaign}
      title={
        currentManager
          ? currentManager.full_name
          : formatMessage({
              id: 'app.views.performance.performance_step_assess_manager_list.assess_managers',
              defaultMessage: 'Assess managers',
            })
      }
      person={currentManager}
      subtitle={
        currentManager
          ? getPersonDisplayTitle(formatMessage, currentManager)
          : undefined
      }
      // this page's content is full of cards so we don't need
      // an outer card
      wrapBodyInCard={false}
    >
      <PerformanceStepAssessManagerListProceedButton
        currentManager={currentManager}
        managersRemaining={managersRemaining}
        object={object}
        isDemoOrPreviewMode={isDemoOrPreviewMode}
        callback={callback}
      />
      {currentManager && (
        <>
          <Card>
            <CardBody>
              <PerformancePersonAssessment
                // @ts-expect-error
                campaign={campaign}
                setCampaign={setCampaign}
                // subtract 1 because we don't consider the step done until we submit
                // ALL the managers
                currentStepNumber={currentStepNumber - 1}
                relationshipType={
                  currentManager.isAdditionalManager
                    ? RELATIONSHIP_TYPES.REPORTS_TO_ADDITIONAL_MANAGER
                    : RELATIONSHIP_TYPES.REPORTS_TO
                }
                callback={callback}
                person={currentManager}
                // this feature is deprecated for upward feedback
                // (isOnFirstPage,setIsOnFirstPage,setIsContinueDisabled are dependent on this)
                allowContributionFeedback={false}
                isUpwardFeedbackToManager={true}
                openResponseQuestions={freeResponseQuestions}
                autoFocus={true}
              />
            </CardBody>
          </Card>
        </>
      )}
      {!currentManager && (
        <>
          {!(sortedManagers?.length > 0) && (
            <div className="mb-4">
              <FormattedMessage
                id="app.views.performance.performance_step_assess_manager_list.no_direct_reports"
                defaultMessage="
              You do not have any managers. You are free to skip this
              step!
            "
              />
            </div>
          )}
          {sortedManagers?.length > 0 && !isEligibleToGiveUpwardFeedback && (
            <PerformanceStepAssessManagerListNonEligible
              object={object}
              campaign={campaign}
              onCallback={callback}
            />
          )}
          {sortedManagers?.length > 0 && isEligibleToGiveUpwardFeedback && (
            <>
              <Card>
                <PerformanceStepAssessManagerListTable
                  managerUpwardFeedbackComplete={managerUpwardFeedbackComplete}
                  setCurrentManager={setCurrentManager}
                  sortedManagers={sortedManagers}
                  toggleSortByName={toggleSortByName}
                  sortedManagersNondatasetRelationships={
                    sortedManagersNondatasetRelationships
                  }
                />
              </Card>
            </>
          )}
        </>
      )}
    </PerformancePage>
  );
};

const mapStateToProps = (state: ReduxState) => {
  const {
    me,
    currentOrganization,
    currentPerfSurveyResponse,
    demoPeople,
    features,
  } = state;

  return {
    me,
    currentOrganization,
    currentPerfSurveyResponse,
    demoPeople,
    features,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentPerfSurveyResponse: (changes) =>
      dispatch(setCurrentPerfSurveyResponse(changes)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(React.memo(PerformanceStepAssessManagerList)));
