import { Col, Row } from 'reactstrap';
import React, { FC } from 'react';

import Avatar from 'views/Widgets/People/Avatar';
import { FormattedMessage } from 'react-intl';
import ScrollableUncontrolledPopover from 'views/Widgets/ScrollableUncontrolledPopover';
import { TeamDashboardPerson } from 'types';

interface Props {
  uniqueId: string;
  person: TeamDashboardPerson;
}

const TeamDashboardManagerRatingPopover: FC<Props> = ({ uniqueId, person }) => {
  return (
    <>
      {person.draft_rating_provided_by && (
        <ScrollableUncontrolledPopover
          placement="left"
          trigger="hover"
          target={'row-draft-rating-' + uniqueId}
        >
          <Row className="flex-nowrap">
            <Col className="col-auto">
              <Avatar
                person={person.draft_rating_provided_by}
                size="xs"
                isExternalUrl={true}
              />
            </Col>
            <Col className="col ms-n3">
              <div className="comment-body d-block px-3 py-2 small">
                <Row>
                  <Col>
                    <span className="fw-bold small">
                      <span className="text-dark">
                        {person.draft_rating_provided_by.full_name}
                      </span>
                      {person.draft_rating_provided_by.title && (
                        <span className="fw-normal">
                          <FormattedMessage
                            id="app.views.widgets.dashboards.team_dashboard.comma"
                            defaultMessage=","
                          />{' '}
                          {person.draft_rating_provided_by.title}
                        </span>
                      )}
                    </span>
                    {person.draft_rating_comments && (
                      <div className="mb-0 py-1">
                        {person.draft_rating_comments}
                      </div>
                    )}
                    {!person.draft_rating_comments && (
                      <div className="mb-0 py-1 fst-italic">
                        <FormattedMessage
                          id="app.views.widgets.dashboards.team_dashboard.no_comments_provided"
                          defaultMessage="No comments provided"
                        />
                      </div>
                    )}
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          {person.draft_rating_additional_managers.length > 0 && (
            <Row className="mt-3">
              <h4>
                <FormattedMessage
                  id="app.views.widgets.dashboards.team_dashboard.rating.popover.additional_managers"
                  defaultMessage="{count, plural, one {Additional manager} other {Additional managers}}"
                  values={{
                    count: person.draft_rating_additional_managers.length,
                  }}
                />
              </h4>
            </Row>
          )}
          {person.draft_rating_additional_managers.map(
            ({ manager, draft_rating, draft_rating_comments }) => (
              <Row
                key={`draft-rating-p-${person.id}-am-${manager.id}`}
                className="flex-nowrap mt-2"
              >
                <Col className="col-auto">
                  <Avatar person={manager} size="xs" isExternalUrl={true} />
                </Col>
                <Col className="col ms-n3">
                  <div className="comment-body d-block px-3 py-2 small">
                    <Row>
                      <Col>
                        <span className="fw-bold small">
                          <span className="text-dark">{manager.full_name}</span>
                          {manager.title && (
                            <span className="fw-normal">
                              {', '}
                              {manager.title}
                            </span>
                          )}
                        </span>
                        {draft_rating && (
                          <div className="mb-0 py-1">
                            <FormattedMessage
                              id="app.views.widgets.dashboards.team_dashboard.additional_managers.popover.rating"
                              defaultMessage="Rating provided: {draft_rating}"
                              values={{
                                draft_rating,
                              }}
                            />
                          </div>
                        )}
                        {!draft_rating && (
                          <div className="mb-0 py-1 fst-italic">
                            <FormattedMessage
                              id="app.views.widgets.dashboards.team_dashboard..additional_managers.popover.no_rating_provided"
                              defaultMessage="No rating provided"
                            />
                          </div>
                        )}
                        {draft_rating_comments && (
                          <div className="mb-0 py-1">
                            {draft_rating_comments}
                          </div>
                        )}
                        {!draft_rating_comments && (
                          <div className="mb-0 py-1 fst-italic">
                            <FormattedMessage
                              id="app.views.widgets.dashboards.team_dashboard.additional_managers.popover.no_comments_provided"
                              defaultMessage="No comments provided"
                            />
                          </div>
                        )}
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            )
          )}
        </ScrollableUncontrolledPopover>
      )}
    </>
  );
};

export default TeamDashboardManagerRatingPopover;
