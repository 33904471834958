import { Button, Progress } from 'reactstrap';
import React, { FC } from 'react';

import Avatar from '../../Widgets/People/Avatar';
import { FormattedMessage } from 'react-intl';

interface Props {
  sortedManagers: any[];
  toggleSortByName: () => void;
  sortedManagersNondatasetRelationships: any[];
  managerUpwardFeedbackComplete: (r: any) => boolean;
  setCurrentManager: (p: any) => void;
}

const PerformanceStepAssessManagerListTable: FC<Props> = ({
  sortedManagers,
  toggleSortByName,
  sortedManagersNondatasetRelationships,
  managerUpwardFeedbackComplete,
  setCurrentManager,
}) => {
  return (
    <table className="table table-sm card-table">
      <thead>
        <tr>
          <th onClick={toggleSortByName} scope="col" role="button">
            <FormattedMessage
              id="app.views.performance.performance_step_assess_manager_list.manager"
              defaultMessage="Manager"
            />
          </th>
          <th scope="col" role="button">
            <FormattedMessage
              id="app.views.performance.performance_step_assess_manager_list.status"
              defaultMessage="Status"
            />
          </th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody className="fs-base">
        {sortedManagers.map((p, index) => {
          const r = sortedManagersNondatasetRelationships[index];
          const feedbackComplete = managerUpwardFeedbackComplete(r);

          return (
            <tr role="button" key={index} onClick={() => setCurrentManager(p)}>
              <th scope="row">
                <div className="flex-nowrap row align-items-center">
                  <div className="col-auto">
                    <Avatar size="xs" person={p} />
                  </div>
                  <div className="col">
                    <div className="p-0 row">
                      <div className="ms-n3 col">
                        <h4 className="m-0 text-dark">{p.full_name}</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </th>
              <td>
                {!r && (
                  <FormattedMessage
                    id="app.views.performance.performance_step_assess_manager_list.not_started"
                    defaultMessage="Not started"
                  />
                )}
                {r && !feedbackComplete && (
                  <Progress
                    className="progress-sm"
                    value={
                      r?.rating !== null
                        ? 75
                        : r?.negative_comments?.length > 0
                        ? 50
                        : r?.positive_comments?.length > 0
                        ? 25
                        : 0
                    }
                  />
                )}
                {feedbackComplete && (
                  <span className="text-primary">
                    <FormattedMessage
                      id="app.views.performance.performance_step_assess_manager_list.done"
                      defaultMessage="Done"
                    />
                  </span>
                )}
              </td>
              <td className="text-end">
                {!r && (
                  <Button className="btn-sm" color="primary">
                    <FormattedMessage
                      id="app.views.performance.performance_step_assess_manager_list.start_review"
                      defaultMessage="Start review"
                    />
                  </Button>
                )}
                {r &&
                  (feedbackComplete ? (
                    <Button className="btn-sm" color="outline-primary">
                      <FormattedMessage
                        id="app.views.performance.performance_step_assess_manager_list.edit_review"
                        defaultMessage="Edit review"
                      />
                    </Button>
                  ) : (
                    <Button className="btn-sm" color="primary">
                      <FormattedMessage
                        id="app.views.performance.performance_step_assess_manager_list.continue"
                        defaultMessage="Continue"
                      />
                    </Button>
                  ))}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default React.memo(PerformanceStepAssessManagerListTable);
