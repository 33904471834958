import { Col, Row } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { ICONS, PROMOTION_PACKETS } from 'consts/consts';
import React, { FC } from 'react';

import Avatar from 'views/Widgets/People/Avatar';
import { Link } from 'react-router-dom';
import { PACKET_STATUSES } from 'utils/models/PromotionPackets';
import SafeUncontrolledPopover from 'components/SafeUncontrolledPopover';
import ScrollableUncontrolledPopover from 'views/Widgets/ScrollableUncontrolledPopover';
import { TeamDashboardPerson } from 'types';

interface Props {
  uniqueId: string;
  person: TeamDashboardPerson;
  promotionPacketsRequired: boolean;
  showPromo: boolean;
  setCurrentPromotionPacketId: (id?: any) => void;
}

const TeamDashboardManagerPromotion: FC<Props> = ({
  uniqueId,
  person,
  promotionPacketsRequired,
  showPromo,
  setCurrentPromotionPacketId,
}) => {
  const { formatMessage } = useIntl();
  return (
    <>
      {promotionPacketsRequired && (
        <>
          <span
            id={'row-role-recommend-for-promo-' + uniqueId}
            role={person?.packet?.id ? 'button' : undefined}
            onClick={
              person?.packet?.id
                ? () => setCurrentPromotionPacketId(person?.packet?.id)
                : undefined
            }
          >
            {person.recommend_for_promotion && (
              <span>
                <i className={ICONS.PROMOTION} />
              </span>
            )}
            {person.packet ? (
              <span>
                <i
                  className={
                    // @ts-expect-error
                    PACKET_STATUSES.find((s) => s.id === person.packet?.status)
                      .icon
                  }
                />
              </span>
            ) : (
              <span>
                {person.recommend_for_promotion && (
                  <i className="fe fe-help-circle text-muted" />
                )}
              </span>
            )}
          </span>
          <SafeUncontrolledPopover
            placement="left"
            trigger="hover"
            target={'row-role-recommend-for-promo-' + uniqueId}
          >
            {person.packet && (
              <>
                <i
                  className={
                    'pe-2 ' +
                    // @ts-expect-error
                    PACKET_STATUSES.find((s) => s.id === person.packet?.status)
                      .icon
                  }
                />
                {
                  // @ts-expect-error
                  PACKET_STATUSES.find((s) => s.id === person.packet?.status)
                    .description
                }
                <br />
                <br />
                <Link
                  to={
                    PROMOTION_PACKETS(formatMessage).path +
                    '/' +
                    person.packet.id
                  }
                  target={'_blank'}
                  rel="noopener noreferrer"
                >
                  <FormattedMessage
                    id="app.views.widgets.dashboards.team_dashboard.open_packet_in_new_window"
                    defaultMessage="Open packet in new window"
                  />{' '}
                  <i className="fe fe-external-link" />
                </Link>
              </>
            )}
            {!person.packet && (
              <>
                <i className="fe fe-help-circle text-muted pe-2" />
                <FormattedMessage
                  id="app.views.widgets.dashboards.team_dashboard.no_promo_packet_created_yet"
                  defaultMessage="No promotion packet has been created yet."
                />
                <br />
                <br />
                <Link
                  to={PROMOTION_PACKETS(formatMessage).path}
                  target={'_blank'}
                  rel="noopener noreferrer"
                >
                  <FormattedMessage
                    id="app.views.widgets.dashboards.team_dashboard.create_promo_packet_action"
                    defaultMessage="Create promotion packet"
                  />{' '}
                  <i className="fe fe-external-link" />
                </Link>
              </>
            )}
          </SafeUncontrolledPopover>
        </>
      )}
      {!promotionPacketsRequired &&
        showPromo &&
        person.recommend_for_promotion && (
          <>
            <span id={'row-role-recommend-for-promo-' + uniqueId}>
              <i className={ICONS.PROMOTION} />
            </span>
            {!!person.promotion_comments?.length && (
              <ScrollableUncontrolledPopover
                placement="left"
                trigger="hover"
                target={'row-role-recommend-for-promo-' + uniqueId}
              >
                {person.promotion_comments}
                {person.recommend_for_promotion_additional_managers.length >
                  0 && (
                  <div>
                    <Row className="mt-3">
                      <h4>
                        <FormattedMessage
                          id="app.views.widgets.dashboards.team_dashboard.rating.popover.additional_managers"
                          defaultMessage="{count, plural, one {Additional manager} other {Additional managers}}"
                          values={{
                            count:
                              person.recommend_for_promotion_additional_managers
                                .length,
                          }}
                        />
                      </h4>
                    </Row>
                    {person.recommend_for_promotion_additional_managers.map(
                      ({
                        manager,
                        recommend_for_promotion,
                        promotion_comments,
                      }) => (
                        <Row
                          key={`recommend-promotion-p-${person.id}-am-${manager.id}`}
                          className="flex-nowrap mt-2"
                        >
                          <Col className="col-auto">
                            <Avatar
                              person={manager}
                              size="xs"
                              isExternalUrl={true}
                            />
                          </Col>
                          <Col className="col ms-n3">
                            <div className="comment-body d-block px-3 py-2 small">
                              <Row>
                                <Col>
                                  <span className="fw-bold small">
                                    <span className="text-dark">
                                      {manager.full_name}
                                    </span>
                                    {manager.title && (
                                      <span className="fw-normal">
                                        {', '}
                                        {manager.title}
                                      </span>
                                    )}
                                  </span>

                                  <div className="mb-0 py-1">
                                    <FormattedMessage
                                      id="app.views.widgets.dashboards.team_dashboard.additional_managers.recommended_salary_increase"
                                      defaultMessage="Recommended: {value, select, true {Yes} other {No}}"
                                      values={{
                                        value: recommend_for_promotion,
                                      }}
                                    />
                                  </div>
                                  {promotion_comments && (
                                    <div className="mb-0 py-1">
                                      {promotion_comments}
                                    </div>
                                  )}
                                </Col>
                              </Row>
                            </div>
                          </Col>
                        </Row>
                      )
                    )}
                  </div>
                )}
              </ScrollableUncontrolledPopover>
            )}
          </>
        )}
    </>
  );
};

export default TeamDashboardManagerPromotion;
