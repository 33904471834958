import { FormattedList, FormattedMessage } from 'react-intl';
import { PerformanceProgressCompletion, TeamDashboardPerson } from 'types';
import { PopoverBody, UncontrolledPopover } from 'reactstrap';
import React, { FC, useMemo } from 'react';

import Avatar from '../People/Avatar';
import AvatarGroup from '../People/AvatarGroup';
import { ICONS } from 'consts/consts';
import { Link } from 'react-router-dom';
import { xorBy } from 'lodash';

interface Props {
  uniqueId: string;
  person: TeamDashboardPerson;
}

const CycleManagers: FC<Props> = ({ uniqueId, person }) => {
  const additionalManagers = useMemo(
    () => person?.additional_managers ?? [],
    [person?.additional_managers]
  );
  const previousManagers = useMemo(
    () =>
      (person?.previous_managers ?? []).filter(
        // do not show previous managers that are also additional managers
        (pm) => !additionalManagers.some((am) => am.id === pm.id)
      ),
    [person?.previous_managers, additionalManagers]
  );
  const believedManager = useMemo(
    () => person.believed_manager,
    [person.believed_manager]
  );

  const believedAdditionalManagers = useMemo(
    () => person.believed_additional_managers ?? [],
    [person.believed_additional_managers]
  );

  const believedAdditionalManagersDiffers = useMemo(() => {
    return (
      person.perf_progress_completions.has(
        PerformanceProgressCompletion.BELIEVED_ADDITIONAL_MANAGERS
      ) &&
      xorBy(believedAdditionalManagers, additionalManagers, 'id').length > 0
    );
  }, [believedAdditionalManagers, additionalManagers, person]);

  const manager = useMemo(() => person.manager, [person.manager]);

  return (
    <>
      {(believedManager || believedAdditionalManagersDiffers) && (
        <>
          <h4
            id={'believed-manager-' + uniqueId}
            className={ICONS.ALERT + ' position-absolute'}
            style={{
              marginLeft: '-1.2rem',
              marginTop: '0.1rem',
            }}
          ></h4>
          <UncontrolledPopover
            placement="left"
            trigger="hover"
            target={'believed-manager-' + uniqueId}
          >
            {believedManager && (
              <>
                <FormattedMessage
                  id="app.views.widgets.dashboards.team_dashboard.believed_manager"
                  defaultMessage="This person believes their manager is <br></br>{manager}{noManagerOptionalText}."
                  values={{
                    br: () => <br />,
                    manager: (
                      <Link
                        target="_blank"
                        rel="noopener noreferrer"
                        to={believedManager.url}
                      >
                        {believedManager.full_name}
                      </Link>
                    ),
                    noManagerOptionalText: !manager && (
                      <FormattedMessage
                        id="app.views.widgets.dashboards.team_dashboard.no_manager_optional"
                        defaultMessage=", but Confirm has no official manager on file. To grant this manager access, contact your Confirm representative"
                      />
                    ),
                  }}
                />
                <br />
              </>
            )}
            {believedAdditionalManagersDiffers && (
              <>
                {!believedAdditionalManagers.length && (
                  <FormattedMessage
                    id="app.views.widgets.dashboards.team_dashboard.believed_have_no_additional_managers"
                    defaultMessage="This person believes they have no additional managers."
                  />
                )}
                {!!believedAdditionalManagers.length && (
                  <FormattedMessage
                    id="app.views.widgets.dashboards.team_dashboard.believed_different_additional_managers"
                    defaultMessage="This person believes their additional managers are <br></br>{managers}"
                    values={{
                      br: () => <br />,
                      managers: (
                        <FormattedList
                          value={believedAdditionalManagers.map((bm) => (
                            <Link
                              key={
                                person.id + '-believed-manager-warn-' + bm.id
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                              to={bm.url}
                            >
                              {bm.full_name}
                            </Link>
                          ))}
                        ></FormattedList>
                      ),
                    }}
                  />
                )}
              </>
            )}
          </UncontrolledPopover>
        </>
      )}
      {/* Case with no manager, no previous managers, no additional managers, but a specified believed manager  */}
      {!(previousManagers.length > 0) &&
        !(additionalManagers.length > 0) &&
        !manager &&
        believedManager && (
          <>
            {believedManager && (
              <Avatar
                linked={believedManager.id ? true : false} // open externally to avoid interrupting flow
                isExternalUrl={true}
                className="me-2"
                size="xs"
                person={believedManager}
              />
            )}
          </>
        )}
      <span>
        {/* Case with manager, no previous managers, no additional managers */}
        {!(previousManagers.length > 0) && !(additionalManagers.length > 0) && (
          <>
            {manager && (
              <Avatar
                linked={manager.id ? true : false} // open externally to avoid interrupting flow
                isExternalUrl={true}
                className="me-2"
                size="xs"
                person={manager}
              />
            )}
          </>
        )}
        {/* Case with manager, with previous managers or additional managers */}
        {(previousManagers.length > 0 || additionalManagers.length > 0) && (
          <AvatarGroup // open externally to avoid interrupting flow
            isExternalUrl={true}
            className="me-2"
            size="xs"
            people={[
              manager
                ? manager
                : believedManager
                ? believedManager
                : {
                    full_name: 'Unknown manager',
                  },
              ...additionalManagers,
              ...previousManagers,
            ]}
            hideAllButFirst={true} // show only first manager visually, rest are in a + menu
            additionalPeoplePopover={
              <>
                <PopoverBody>
                  {additionalManagers.length > 0 && (
                    <>
                      <div className="text-body">
                        <h4>
                          <FormattedMessage
                            id="app.views.widgets.dashboards.team_dashboard.additional_managers"
                            defaultMessage="{count, plural, one {Additional manager} other {Additional managers}}"
                            values={{
                              count: additionalManagers.length,
                            }}
                          />
                        </h4>
                      </div>
                      <div className="mb-3">
                        <FormattedList
                          value={additionalManagers.map((p, extraIndex) => (
                            <Link
                              key={
                                person.id + '-additional_manager-' + extraIndex
                              }
                              to={p.url}
                            >
                              {p.full_name}
                            </Link>
                          ))}
                        />
                      </div>
                    </>
                  )}
                  {previousManagers.length > 0 && (
                    <>
                      <div className="text-body">
                        <h4>
                          <FormattedMessage
                            id="app.views.widgets.dashboards.team_dashboard.previous_managers_mentioned"
                            defaultMessage="{count, plural, one {Previous manager mentioned} other {Previous managers mentioned}}"
                            values={{
                              count: previousManagers.length,
                            }}
                          />
                        </h4>
                      </div>
                      <div>
                        <FormattedList
                          value={previousManagers.map((p, extraIndex) => (
                            <Link
                              key={
                                person.id + '-previous_manager-' + extraIndex
                              }
                              to={p.url}
                            >
                              {p.full_name}
                            </Link>
                          ))}
                        />
                      </div>
                    </>
                  )}
                </PopoverBody>
              </>
            }
          />
        )}
      </span>
    </>
  );
};

export default React.memo(CycleManagers);
