import { Col, Row } from 'reactstrap';
// import { Col, Row } from 'reactstrap';
import React, { FC } from 'react';

import Avatar from 'views/Widgets/People/Avatar';
import { FormattedMessage } from 'react-intl';
import { ICONS } from 'consts/consts';
import ScrollableUncontrolledPopover from 'views/Widgets/ScrollableUncontrolledPopover';
import { TeamDashboardPerson } from 'types';

interface Props {
  uniqueId: string;
  person: TeamDashboardPerson;
  showSalaryIncrease: boolean;
}

const TeamDashboardManagerSalaryIncrease: FC<Props> = ({
  uniqueId,
  person,
  showSalaryIncrease,
}) => {
  return (
    <>
      {showSalaryIncrease && person.recommend_for_salary_increase && (
        <>
          <span id={'row-role-recommend-for-salary-increase-' + uniqueId}>
            <i className={ICONS.SALARY_INCREASE} />
          </span>
          {!!person.salary_increase_comments?.length && (
            <ScrollableUncontrolledPopover
              placement="left"
              trigger="hover"
              target={'row-role-recommend-for-salary-increase-' + uniqueId}
            >
              {person.salary_increase_comments}
              {person.recommend_for_salary_increase_additional_managers.length >
                0 && (
                <div>
                  <Row className="mt-3">
                    <h4>
                      <FormattedMessage
                        id="app.views.widgets.dashboards.team_dashboard.rating.popover.additional_managers"
                        defaultMessage="{count, plural, one {Additional manager} other {Additional managers}}"
                        values={{
                          count:
                            person
                              .recommend_for_salary_increase_additional_managers
                              .length,
                        }}
                      />
                    </h4>
                  </Row>
                  {person.recommend_for_salary_increase_additional_managers.map(
                    ({
                      manager,
                      recommend_for_salary_increase,
                      salary_increase_comments,
                    }) => (
                      <Row
                        key={`salart-increase-p-${person.id}-am-${manager.id}`}
                        className="flex-nowrap mt-2"
                      >
                        <Col className="col-auto">
                          <Avatar
                            person={manager}
                            size="xs"
                            isExternalUrl={true}
                          />
                        </Col>
                        <Col className="col ms-n3">
                          <div className="comment-body d-block px-3 py-2 small">
                            <Row>
                              <Col>
                                <span className="fw-bold small">
                                  <span className="text-dark">
                                    {manager.full_name}
                                  </span>
                                  {manager.title && (
                                    <span className="fw-normal">
                                      {', '}
                                      {manager.title}
                                    </span>
                                  )}
                                </span>

                                <div className="mb-0 py-1">
                                  <FormattedMessage
                                    id="app.views.widgets.dashboards.team_dashboard.additional_managers.recommended_salary_increase"
                                    defaultMessage="Recommended: {value, select, true {Yes} other {No}}"
                                    values={{
                                      value: recommend_for_salary_increase,
                                    }}
                                  />
                                </div>
                                {salary_increase_comments && (
                                  <div className="mb-0 py-1">
                                    {salary_increase_comments}
                                  </div>
                                )}
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    )
                  )}
                </div>
              )}
            </ScrollableUncontrolledPopover>
          )}
        </>
      )}
    </>
  );
};

export default TeamDashboardManagerSalaryIncrease;
