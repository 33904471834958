import {
  Button,
  Card,
  CardBody,
  Col,
  PopoverBody,
  Row,
  UncontrolledPopover,
} from 'reactstrap';
import CampaignParticipantsAddPeopleModal, {
  AddDatasetPeopleResponse,
} from './CampaignParticipantsAddPeopleModal';
import CampaignParticipantsBulkEditorModal, {
  ApiStatus,
} from './CampaignParticipantsBulkEditorModal';
import {
  CampaignWithConfigs,
  Dataset,
  Features,
  Organization,
  Person,
  ReduxState,
} from '../../../types';
import {
  FORMAT_AVATAR_GROUP,
  FORMAT_AVATAR_ONLY,
  FORMAT_AVATAR_WITH_TITLE,
  getIconForFieldName,
} from '../../Widgets/People/Filters/common';
import { FormattedMessage, useIntl } from 'react-intl';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  formatNamesForCsv,
  peopleArrayCountThenNameSort,
} from 'utils/models/Person';

import CampaignParticipantsSnapshotEditorModal from './CampaignParticipantsSnapshotEditorModal';
import ConfirmAPI from '../../../utils/api/ConfirmAPI';
import EmptyState from '../../Widgets/EmptyState';
import FilterablePeopleTable from '../../Widgets/People/FilterablePeopleTable';
import Loading from '../../Widgets/Loading';
import { comparePeople } from '../../../utils/util/util';
import { connect } from 'react-redux';
import { loadOrRender } from '../../../utils/util/formatter';
import { toast } from 'react-toastify';
import { uniqueId } from 'lodash';
import { useAuth0 } from '@auth0/auth0-react';
import { useConfiguration } from './configuration';
import { useFeatures } from 'utils/util/features';

type Props = {
  campaign: CampaignWithConfigs;
  currentOrganization: Organization;
  currentProxyPerson?: Person;
  features: Features;
};

const getEmailFromRow = (row) => row.person?.email;

const CampaignParticipants: FC<Props> = (props) => {
  const { formatMessage } = useIntl();
  const { ifEnabled } = useFeatures();

  const [dataset, setDataset] = useState<Dataset | undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState(null);
  const [selectedRowEmailsSet, setSelectedRowEmailsSet] = useState(
    new Set<unknown>()
  );
  const [bulkEditModalIsOpen, setBulkEditModalIsOpen] =
    useState<boolean>(false);

  const handleToggleBulkModal = useCallback(() => {
    setBulkEditModalIsOpen((value) => !value);
  }, []);

  const [addModalIsOpen, setAddModalIsOpen] = useState<boolean>(false);

  const handleToggleAddModal = useCallback(() => {
    setAddModalIsOpen((value) => !value);
  }, []);

  const [forceRefresh, setForceRefresh] = useState<number>(0);

  const { user } = useAuth0();
  const userSub = user?.sub;
  const currentOrgId = props.currentOrganization?.id;
  const datasetId = props.campaign?.dataset_id;

  const campaignSurveyResponseParticipationOptions = useConfiguration(
    props.campaign
  );

  const prepopulateMissingFields = useCallback(
    (datasetRow) => {
      const defaults = campaignSurveyResponseParticipationOptions.reduce(
        (
          agg: object,
          option: {
            field: string;
            defaultValue?: unknown;
            [key: string]: unknown;
          }
        ) => {
          if (typeof option.defaultValue !== 'undefined') {
            return {
              ...agg,
              [option.field]: option.defaultValue,
            };
          }
          return agg;
        },
        {}
      );

      // if is_participating is set to false, set all other participation options to false
      const valueOverrides = {};
      if (datasetRow.is_participating === false) {
        Object.keys(defaults).forEach((key) => {
          valueOverrides[key] = false;
        });
      }

      return {
        ...defaults,
        ...datasetRow,
        ...valueOverrides,
      };
    },
    [campaignSurveyResponseParticipationOptions]
  );

  useEffect(() => {
    // fetch dataset for campaign from server
    if (userSub && currentOrgId && datasetId) {
      ConfirmAPI.getObject(
        userSub,
        props.currentProxyPerson,
        ConfirmAPI.OBJECT_TYPES.DATASETS,
        datasetId,
        (data) => {
          setDataset(data);
        },
        (message) => {
          setErrorMessage(message);
        },
        {
          organization: currentOrgId,
          ...(props.currentProxyPerson
            ? { proxy: props.currentProxyPerson.email }
            : {}),
        }
      );
    }
  }, [
    datasetId,
    currentOrgId,
    props.currentProxyPerson,
    userSub,
    forceRefresh,
  ]);

  const rows = useMemo(() => {
    if (typeof dataset === 'undefined') {
      return undefined;
    }

    const data = dataset?.data;

    if (!Array.isArray(data)) {
      return [];
    }

    return (
      data
        // @ts-expect-error
        .filter((p) => !p?.is_artificially_added) // don't show if artificially added to data
        .map((p) => {
          const row = prepopulateMissingFields(p);

          if (!row.person) {
            // take all fields from row itself if person wasn't found
            // via Elasticsearch lookup in the backend (could be a bad
            // email address or something)
            row.person = row;

            if (!row.full_name) {
              row.full_name_not_found =
                (row.preferred_given_name ??
                  row.legal_given_name ??
                  row.given_name) +
                ' ' +
                row.family_name;
              const uniId = uniqueId('participant_not_found_');

              // show warning indicating this person was not found
              row.full_name = (
                <>
                  <span>{row.full_name_not_found}</span>
                  <span
                    id={uniId}
                    className="badge rounded-pill bg-warning my-0 ms-2"
                    style={{
                      position: 'relative',
                      top: '-1px',
                    }}
                  >
                    <FormattedMessage
                      id="app.views.administration.campaign_participants.data.not_found"
                      defaultMessage="
                  Not found
                "
                    />
                  </span>
                  <UncontrolledPopover
                    trigger="hover"
                    placement="top"
                    target={uniId}
                  >
                    <PopoverBody>
                      <span className="text-dark">
                        <FormattedMessage
                          id="app.views.administration.campaign_participants.data.not_found.message"
                          defaultMessage="<span>{email}</span> was not
                      found. Add this person to your organization before
                      continuing.
                    "
                          values={{
                            span: (msg) => (
                              <span className="fw-bold">{msg}</span>
                            ),
                            email: row.email,
                          }}
                        />
                      </span>
                    </PopoverBody>
                  </UncontrolledPopover>
                </>
              );
            }
          }

          return row;
        })
    );
  }, [dataset, prepopulateMissingFields]);

  const columns = useMemo(() => {
    return [
      {
        name: formatMessage({
          id: 'app.views.administration.campaign_participants.name.person',
          defaultMessage: 'Participant',
        }),
        field: 'person',
        isPerson: true,
        format: FORMAT_AVATAR_WITH_TITLE,
        csvName: ['Name', 'Email'],
        csvFormat: (c) => ({
          Name: c.full_name_not_found
            ? formatMessage(
                {
                  id: 'app.views.administration.campaign_participants.name.person.not_found',
                  defaultMessage: '{full_name} (not found)',
                },
                { full_name: c.full_name_not_found }
              )
            : c.full_name,
          Email: c.email,
        }),
        getFilterDisplayValue: (c) => c.full_name,
        sort: (a, b) => comparePeople(a?.candidate_person, b?.candidate_person),
      },
      {
        name: formatMessage({
          id: 'app.views.administration.campaign_participants.name.manager',
          defaultMessage: 'Mgr',
        }),
        field: 'manager',
        isPerson: true,
        format: FORMAT_AVATAR_ONLY,
        csvFormat: (p) => p.email || '',
        getFilterDisplayValue: (p) => p.full_name,
        nameTransformerFunction: (name) => name + ' as manager',
        ifEmpty: '-',
        popoverContent: (
          <FormattedMessage
            id="app.views.administration.campaign_participants.popover_content.manager"
            defaultMessage="Manager"
          />
        ),
        sort: (a, b) => comparePeople(a?.manager, b?.manager),
      },
      ...ifEnabled<any[]>(
        'additional_managers',
        [
          {
            name: formatMessage({
              id: 'app.widgets.task.additional_managers.abbreviated.header',
              defaultMessage: 'Additional Mgr(s)',
            }),
            field: 'additional_managers',
            format: FORMAT_AVATAR_GROUP,
            multi: true,
            csvFormat: formatNamesForCsv,
            sort: (a, b) =>
              peopleArrayCountThenNameSort(
                a.additional_managers,
                b.additional_managers
              ),
          },
        ],
        []
      ),
      ...campaignSurveyResponseParticipationOptions.map((c) => {
        return {
          ...c,
          filterIcon: getIconForFieldName(c.field),
        };
      }),
    ];
  }, [campaignSurveyResponseParticipationOptions, formatMessage, ifEnabled]);

  const toggleSelectedRowField = useCallback(
    (rowEmail) => {
      if (selectedRowEmailsSet.has(rowEmail)) {
        setSelectedRowEmailsSet(
          new Set(
            Array.from(selectedRowEmailsSet).filter((re) => re !== rowEmail)
          )
        );
      } else {
        setSelectedRowEmailsSet(
          new Set([...Array.from(selectedRowEmailsSet), rowEmail])
        );
      }
    },
    [selectedRowEmailsSet]
  );

  const handleSuccess = useCallback((status: ApiStatus) => {
    if (status === 'unchanged') {
      return;
    }
    setDataset(undefined);
    setSelectedRowEmailsSet(new Set());
    setForceRefresh((value) => value + 1);
  }, []);

  const [snapshotEditorModalIsOpen, setSnapshotEditorModalIsOpen] =
    useState(false);

  const handleToggleSnapshotEditorModal = useCallback(() => {
    setSnapshotEditorModalIsOpen(false);
  }, []);

  const handleSnapshotEditorSuccess = useCallback(() => {
    setDataset(undefined);
    setSelectedRowEmailsSet(new Set());
    setForceRefresh((value) => value + 1);
  }, []);

  const handleAddPeopleSuccess = useCallback(
    (response: AddDatasetPeopleResponse) => {
      if (response.status === 'unchanged') return;
      toast.success(
        formatMessage({
          id: 'app.views.administration.campaign_participants.participants_added',
          defaultMessage: 'Participants added successfully.',
        })
      );

      setDataset((dataset) => {
        if (!dataset) {
          throw new Error('Dataset is undefined');
        }

        // before refresh, show added people at the top (instead of sorted)
        // for UX convenience in editing those that were just added
        const newDataset = { ...dataset };
        newDataset.data = response.added.concat(newDataset.data);

        return newDataset;
      });

      setSelectedRowEmailsSet(new Set());
    },
    [formatMessage]
  );

  const output = useMemo(() => {
    if (typeof rows === 'undefined') {
      return <Loading />;
    } else if (rows?.length > 0) {
      return (
        <>
          <CampaignParticipantsBulkEditorModal
            isOpen={bulkEditModalIsOpen}
            onToggle={handleToggleBulkModal}
            selectedValues={rows.filter((row) =>
              selectedRowEmailsSet.has(row.email)
            )}
            inputFields={campaignSurveyResponseParticipationOptions
              .filter((option) => !!option.bulkEdit)
              .map(({ field, csvName, name, bulkEdit }) => ({
                name: field,
                label: csvName ?? name,
                ...bulkEdit,
              }))}
            campaign={props.campaign}
            onSuccess={handleSuccess}
          />
          {addModalIsOpen && (
            <CampaignParticipantsAddPeopleModal
              isOpen={addModalIsOpen}
              onToggle={handleToggleAddModal}
              campaign={props.campaign}
              onSuccess={handleAddPeopleSuccess}
              columns={columns}
            />
          )}
          <FilterablePeopleTable
            arrayValuesUsedForFormatting={true}
            rows={rows}
            columns={columns}
            headerContent={
              <Row className="align-items-center">
                <Col></Col>
                {selectedRowEmailsSet.size > 0 ? (
                  <Col className="col-auto pe-0">
                    <Button
                      color="light"
                      className="btn-sm"
                      onClick={handleToggleBulkModal}
                    >
                      <FormattedMessage
                        id="app.views.administration.campaign_participants.filterable_people_table.header_content.edit"
                        defaultMessage="
                      Edit {selectedEmailsCount} participants..."
                        values={{
                          selectedEmailsCount: selectedRowEmailsSet.size,
                        }}
                      />
                    </Button>
                  </Col>
                ) : undefined}
                <Col className="col-auto pe-0">
                  <Button
                    color="light"
                    className="btn-sm ml-2"
                    onClick={handleToggleAddModal}
                  >
                    <FormattedMessage
                      id="app.views.administration.campaign_participants.filterable_people_table.header_content.add"
                      defaultMessage="Add participants..."
                    />
                  </Button>
                </Col>
              </Row>
            }
            isRowSelectable={(row) => (row.person?.email ? true : false)}
            toggleSelectedRowField={toggleSelectedRowField}
            selectedRowToggleFieldsSet={selectedRowEmailsSet}
            setSelectedRowToggleFieldsSet={setSelectedRowEmailsSet}
            getUniqueRowId={getEmailFromRow}
          ></FilterablePeopleTable>
        </>
      );
    } else {
      return (
        <>
          <Card>
            <CardBody>
              <EmptyState
                title={formatMessage({
                  id: 'app.views.administration.campaign_participants.title.no_participants_for_this_cycle',
                  defaultMessage:
                    'There are no participants added to this cycle yet.',
                })}
                subtitle={formatMessage({
                  id: 'app.views.administration.campaign_participants.title.add_participants',
                  defaultMessage: 'Add participants to the cycle.',
                })}
              >
                <Button
                  color="primary"
                  onClick={() => setSnapshotEditorModalIsOpen(true)}
                >
                  <FormattedMessage
                    id="app.views.administration.campaign_participants.create_snapshot"
                    defaultMessage="Create participants snapshot"
                  />
                </Button>
              </EmptyState>
            </CardBody>
          </Card>
          <CampaignParticipantsSnapshotEditorModal
            isOpen={snapshotEditorModalIsOpen}
            onToggle={handleToggleSnapshotEditorModal}
            campaign={props.campaign}
            onSuccess={handleSnapshotEditorSuccess}
          />
        </>
      );
    }
  }, [
    rows,
    formatMessage,
    bulkEditModalIsOpen,
    handleToggleBulkModal,
    campaignSurveyResponseParticipationOptions,
    props.campaign,
    columns,
    selectedRowEmailsSet,
    toggleSelectedRowField,
    handleSuccess,
    snapshotEditorModalIsOpen,
    handleToggleSnapshotEditorModal,
    handleSnapshotEditorSuccess,
    handleToggleAddModal,
    addModalIsOpen,
    handleAddPeopleSuccess,
  ]);

  const loadOrRenderOutput = loadOrRender(dataset, errorMessage);
  if (loadOrRenderOutput) {
    return loadOrRenderOutput;
  }

  return output;
};

const mapStateToProps = (state: ReduxState) => {
  const { currentOrganization, currentProxyPerson, features } = state;

  return {
    currentOrganization,
    currentProxyPerson,
    features,
  };
};

export default connect(mapStateToProps)(React.memo(CampaignParticipants));
