import { Campaign, Relationship } from '../../../../types';
import alertImage from '../../../../assets/img/icons/alert.png';
import { capitalize } from '../../../../utils/util/formatter';

export const TENURE_GAP_SIZE = 6;

export type ChartCoordinates = {
  x: number;
  y: number;
};

export type PerformanceHistoryData = {
  campaign: Campaign;
  title: string;
  tenure_months: number;
  position_tenure: number;
  rating: {
    value?: number;
  };
  gold_stars: Relationship[];
  influence: Relationship[];
  heads_ups: Relationship[];
  heads_ups_display: string;
  benchmarks: {
    organization: {
      all: {
        gold_stars: number;
        influence: number;
        heads_ups: number;
        positive_ona_avg: number;
      };
      tenure: {
        gold_stars: number;
        influence: number;
        heads_ups: number;
        positive_ona_avg: number;
        positive_ona_percentile: number;
      };
      cross_tenure: {
        buckets: {
          [range: string]: {
            next_point: number;
            num_people: number;
            average_ona: number;
          };
        };
        overall: {
          slope: number;
          intercept: number;
        };
      };
      ratings: {
        [rating: string]: {
          min: number;
          max: number;
        };
      };
      ona: {
        heads_ups: number[];
        influence: number[];
        gold_stars: number[];
        positive_ona: number[];
      };
    };
  };
  ona_percentiles: {
    positive_ona: number;
    gold_stars: number;
    influence: number;
  };
  is_eligible_for_reporting: boolean;
};

export type RatingValues = {
  points: ChartCoordinates[];
  cycles: PerformanceHistoryData[];
};

export type DataValues = {
  individual: {
    data: number[];
    alerts: number[];
  };
  tenure: {
    data: number[];
  };
  baseline: {
    data: number[];
  };
  ratings: {
    data: ChartCoordinates[];
    alerts: number[];
  };
};

export type ONAStats = {
  count: number;
  percentile: number | null;
  difference: number | null;
};

export type CampaignONAStats = {
  goldStars: ONAStats;
  influence: ONAStats;
  headsUps: ONAStats;
};

export const computeRatingData = (
  data?: PerformanceHistoryData[]
): RatingValues => {
  const points: ChartCoordinates[] = [];
  const cycles: PerformanceHistoryData[] = [];

  if (data) {
    data.forEach((dp) => {
      const tenure = dp.tenure_months;
      const value = dp.rating.value;

      if (tenure != null && value != null) {
        points.push({
          x: tenure,
          y: value,
        });
        cycles.push(dp);
      }
    });
  }

  return {
    points,
    cycles,
  };
};

export const computeDataValues = (
  data?: PerformanceHistoryData[],
  ratingValues?: RatingValues
): DataValues => {
  return {
    individual: {
      data:
        data?.map((e) => e.gold_stars.length + e.influence.length || 0) ||
        Array(4).fill(0),
      alerts:
        data?.reduce((acc: number[], e, i) => {
          if (i && e?.title !== data[i - 1]?.title) {
            acc.push(i);
          }
          return acc;
        }, []) || [],
    },
    tenure: {
      data:
        data?.map(
          (e) => e?.benchmarks?.organization?.tenure?.positive_ona_avg || 0
        ) || Array(4).fill(0),
    },
    baseline: {
      data:
        data?.map(
          (e) => e?.benchmarks?.organization?.all?.positive_ona_avg || 0
        ) || Array(4).fill(0),
    },
    ratings: {
      data: ratingValues?.points ?? [],
      alerts:
        ratingValues?.cycles?.reduce((acc: number[], e, i) => {
          if (i && e?.title !== ratingValues.cycles[i - 1]?.title) {
            acc.push(i);
          }
          return acc;
        }, []) || [],
    },
  };
};

export type RatingScaleItem = {
  id: string;
  value: number;
};

export const computeLabels = (
  data: PerformanceHistoryData[]
): [number, number] => {
  let minTenure = 0;
  let maxTenure = 1;

  if (data) {
    const TENURE_BUFFER = 12;
    minTenure = Math.max(
      minTenure,
      (data[0]?.tenure_months || 0) - TENURE_BUFFER
    );
    maxTenure = (data[data.length - 1]?.tenure_months || 0) + TENURE_BUFFER;
  }
  minTenure = Math.floor(minTenure / TENURE_GAP_SIZE) * TENURE_GAP_SIZE;
  maxTenure = Math.ceil(maxTenure / TENURE_GAP_SIZE) * TENURE_GAP_SIZE;

  return [minTenure, maxTenure];
};

export const lineBaseStyle = (
  title: string,
  color: string,
  borderDash?: number[]
) => ({
  label: title,
  fill: true,
  pointBorderWidth: 1,
  pointHoverRadius: 4,
  pointRadius: 4,
  pointHitRadius: 4,
  borderDash: borderDash || [],
  lineTension: 0,
  pointBorderColor: color,
  borderColor: color,
  pointBackgroundColor: color,
});

export const chartAlertPointStyle = (
  dataValues: DataValues,
  type: 'individual' | 'ratings',
  x: { dataIndex: number }
) => {
  const alert = new Image(25, 25);
  alert.src = alertImage;
  return dataValues[type].alerts.includes(x.dataIndex) ? alert : 'circle';
};

export const computeHistoricalCampaignONAData = (
  selectedCampaign?: PerformanceHistoryData,
  selectedCampaignPrevious?: PerformanceHistoryData
): CampaignONAStats => {
  const selectedCampaignGoldStars = selectedCampaign?.gold_stars || [];
  const selectedCampaignInfluence = selectedCampaign?.influence || [];
  const selectedCampaignHeadsUp = selectedCampaign?.heads_ups || [];

  const result = {
    goldStars: {
      count: selectedCampaignGoldStars.length,
      percentile: selectedCampaign?.ona_percentiles?.gold_stars ?? null,
      difference: <number | null>null,
    },
    influence: {
      count: selectedCampaignInfluence.length,
      percentile: selectedCampaign?.ona_percentiles?.influence ?? null,
      difference: <number | null>null,
    },
    headsUps: {
      count: selectedCampaignHeadsUp.length,
      percentile: null,
      difference: <number | null>null,
    },
  };

  if (selectedCampaignPrevious && selectedCampaign?.is_eligible_for_reporting) {
    result.goldStars.difference =
      selectedCampaignGoldStars.length -
      selectedCampaignPrevious?.gold_stars?.length;
    result.influence.difference =
      selectedCampaignInfluence.length -
      selectedCampaignPrevious?.influence?.length;
    result.headsUps.difference =
      selectedCampaignHeadsUp.length -
      selectedCampaignPrevious?.heads_ups?.length;
  }

  return result;
};

export const createDistributionData = (
  formatMessage: (object) => string,
  type: string,
  selectedCampaign?: PerformanceHistoryData,
  selectedCampaignONA?: CampaignONAStats
) => {
  if (selectedCampaign) {
    const benchmarks = [
      ...(selectedCampaign?.benchmarks?.organization?.ona?.[type] ?? []),
    ];

    let lastLabelSuffix = '+';

    const last = benchmarks[benchmarks.length - 1];
    // handle the corner case where few people received
    // 1 ona. In this case the 99th percentile is likely less than 1
    // so we need to include those people in the charts
    if (last < 1) {
      const last_rounded = Math.ceil(last);
      if (last_rounded != last) {
        benchmarks.push(last_rounded);
        lastLabelSuffix = '';
      }
    }

    if (benchmarks) {
      const dataValues: number[] = [];

      let valueIterator = 1.0;
      let valueCounter = 0;
      let valueMax = 0;

      benchmarks.forEach((v) => {
        if (v < valueIterator) {
          valueCounter++;
        } else {
          if (valueCounter > valueMax) {
            valueMax = valueCounter;
          }
          dataValues.push(valueCounter);
          valueIterator += 1.0;
          while (valueIterator <= v) {
            valueIterator += 1.0;
            dataValues.push(0);
          }
          valueCounter = 1;
        }
      });

      // push the values greater that the largest integer
      // found in the benchmarks
      if (valueCounter > 0 && valueMax > 0) {
        dataValues.push(valueCounter);
      }

      // if there are no values, nobody got recognized
      // add a dummy value indicating that 100% of the participants
      // got 0 recognition
      if (dataValues.length === 0) {
        dataValues.push(100);
        lastLabelSuffix = '';
      }

      const valueGap = valueMax > 20 ? 10 : 5;
      // round to the smallest multiple of valueGap that is bigger than
      // valueMax
      if (valueMax % valueGap) {
        valueMax = Math.floor(valueMax / valueGap) * valueGap + valueGap;
      }

      const labels = dataValues.map((_, i) => i.toString());
      const colorConfig = {
        influence: '#B1C2D9',
        gold_stars: '#B1C2D9',
      };

      const highlightColorConfig = {
        influence: '#2c7be5',
        gold_stars: '#f6c343',
      };

      const typeMapping = {
        influence: 'influence',
        gold_stars: 'goldStars',
      };

      let highlightIndex = labels.findIndex(
        (e) => e >= selectedCampaignONA?.[typeMapping[type]]?.count
      );
      if (highlightIndex < 0) {
        highlightIndex = labels.length - 1;
      }

      // if the highlighter bar has value 0
      // add a small bar so the the person can see where the stand
      if (dataValues[highlightIndex] === 0) {
        dataValues[highlightIndex] = 0.25;
      }

      const borderArray = Array(labels.length).fill(colorConfig[type]);
      borderArray[highlightIndex] = highlightColorConfig[type];
      const backgroundArray = Array(labels.length).fill(colorConfig[type]);
      backgroundArray[highlightIndex] = highlightColorConfig[type];
      labels[labels.length - 1] = labels[labels.length - 1] + lastLabelSuffix;

      const chartData = {
        labels,
        datasets: [
          {
            data: dataValues,
            borderColor: borderArray,
            backgroundColor: backgroundArray,
          },
        ],
      };

      const chartOptions = {
        tooltips: {
          enabled: false,
          // this looks like a bug in the chart-js library we are using
          // just disabling the tooltip doesn't make any effect,
          // using an empty custom callback will disable it
          custom: function () {}, // eslint-disable-line
        },
        maintainAspectRatio: true,
        scales: {
          xAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: capitalize(type.replace('_', ' ')),
              },
              ticks: {
                display: true,
                autoSkip: true,
                maxTicksLimit: 1,
              },
              gridLines: {
                display: false,
              },
            },
          ],
          yAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: formatMessage({
                  id: 'performance.trajectory.summary.trajectory_summary.participants_percent',
                  defaultMessage: '% Participants',
                }),
              },
              min: 0,
              max: valueMax,
              ticks: {
                beginAtZero: true,
                display: true,
                //maxTicksLimit: 4,
              },
              gridLines: {
                display: true,
                color: '#edf2f9',
                lineWidth: 2,
              },
            },
          ],
        },
      };

      return {
        chartData,
        chartOptions,
      };
    }
  }
  return null;
};
