import { getFilteredPhaseOpenResponseQuestions } from 'utils/models/Performance';
import { getUnattributedPerson } from '../../../utils/models/Person';
import { toPersonWithSurvey } from 'utils/models/Campaign';
import { Campaign, Person, SurveyResponse } from 'types';

export const getUniqueWordCloudsFromRelationships = (
  formatMessage,
  relationships,
  tagType
) => {
  if (!(relationships?.length > 0)) {
    return [];
  }

  return relationships.reduce(
    (acc, r) =>
      acc.concat(
        r[tagType]
          ? // ensure skills only mentioned once for a given
            // contributor
            r[tagType].map((tag, index) => ({
              wordObject: tag,
              contributedPerson: r.person?.id
                ? r.person
                : getUnattributedPerson(formatMessage, index, r.type),
            }))
          : []
      ),
    []
  );
};

export const getFilteredEvaluationQuestions = (
  targetPerson: Person,
  campaign?: Campaign,
  phaseType?: string,
  questionType?: string,
  surveyResponse?: SurveyResponse
) => {
  if (campaign && campaign.phases?.length > 0) {
    const phase = campaign.phases.find((p) => p.type === phaseType);
    if (phase) {
      return getFilteredPhaseOpenResponseQuestions({
        targetPerson: toPersonWithSurvey(targetPerson, surveyResponse),
        campaign,
        phase,
        type: questionType,
      });
    }
  }
  return [];
};
