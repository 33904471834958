import { CampaignWithConfigs, DatasetRecord } from '../../../types';
import {
  FORMAT_AVATAR_GROUP,
  FORMAT_AVATAR_ONLY,
  FORMAT_AVATAR_WITH_TITLE,
} from '../../Widgets/People/Filters/common';
import { FormattedMessage, useIntl } from 'react-intl';
import React, { FC, useCallback, useMemo, useState } from 'react';
import {
  formatNamesForCsv,
  peopleArrayCountThenNameSort,
} from 'utils/models/Person';

import { ADMINISTRATION_PEOPLE } from '../../../consts/consts';
import EmptyState from '../../Widgets/EmptyState';
import FilterablePeopleTable from '../../Widgets/People/FilterablePeopleTable';
import Loading from '../../Widgets/Loading';
import ModalEditor from 'views/Widgets/Modals/ModalEditor';
import { getId } from '../../../utils/util/util';
import { useConfirmApiWithDefault } from '../../../utils/api/ApiHooks';
import { useFeatures } from 'utils/util/features';

type Props = {
  isOpen: boolean;
  onToggle: () => void;
  campaign: CampaignWithConfigs;
  onSuccess: (response: AddDatasetPeopleResponse) => void;
  columns: object[];
};

interface GetMissingDatasetPeopleResponse {
  people: any[];
  has_hris_integration: boolean;
}

export type AddDatasetPeopleApiStatus = 'modified' | 'unchanged';

export interface AddDatasetPeopleResponse {
  status: AddDatasetPeopleApiStatus;
  dataset_id: number;
  added: DatasetRecord[];
}

const CampaignParticipantsAddPeopleModal: FC<Props> = ({
  isOpen,
  onToggle,
  campaign,
  onSuccess,
}) => {
  const { formatMessage } = useIntl();
  const { ifEnabled } = useFeatures();

  const [selectedRowIdsSet, setSelectedRowIdsSet] = useState(new Set<string>());

  const { data, status } =
    useConfirmApiWithDefault<GetMissingDatasetPeopleResponse>({
      method: 'GET',
      url: `/datasets/${campaign.dataset_id}/get-missing-people`,
      defaultValue: {
        people: [],
        has_hris_integration: false,
      },
    });

  const people = data.people;
  const hasHrisIntegration = data.has_hris_integration;

  const toggleSelectedRowField = useCallback(
    (rowId) => {
      if (selectedRowIdsSet.has(rowId)) {
        setSelectedRowIdsSet(
          new Set(Array.from(selectedRowIdsSet).filter((id) => id !== rowId))
        );
      } else {
        setSelectedRowIdsSet(
          new Set([...Array.from(selectedRowIdsSet), rowId])
        );
      }
    },
    [selectedRowIdsSet]
  );

  const rows = useMemo(() => {
    return people;
  }, [people]);

  const columns = useMemo(
    () => [
      {
        name: (
          <FormattedMessage
            id="app.views.administration.campaign_participants_add_people_modal.table.participant"
            defaultMessage="Participant"
          />
        ),
        field: 'person',
        isPerson: true,
        format: FORMAT_AVATAR_WITH_TITLE,
        getFilterDisplayValue: (c) => c.full_name,
      },
      {
        field: 'location',
        name: formatMessage({
          id: 'app.views.administration.campaign_participants_add_people_modal.table.location',
          defaultMessage: 'Location',
        }),
        popoverContent: (
          <FormattedMessage
            id="app.views.administration.campaign_participants_add_people_modal.table.location_popover"
            defaultMessage="Location"
          />
        ),
      },
      {
        field: 'function',
        name: formatMessage({
          id: 'app.views.administration.campaign_participants_add_people_modal.table.function',
          defaultMessage: 'Function',
        }),
        popoverContent: (
          <FormattedMessage
            id="app.views.administration.campaign_participants_add_people_modal.table.function_popover"
            defaultMessage="Function"
          />
        ),
      },
      {
        name: (
          <FormattedMessage
            id="app.views.administration.campaign_participants_add_people_modal.table.manager"
            defaultMessage="Manager"
          />
        ),
        field: 'manager',
        isPerson: true,
        format: FORMAT_AVATAR_ONLY,
        getFilterDisplayValue: (c) => c.full_name,
      },
      ...ifEnabled<any[]>(
        'additional_managers',
        [
          {
            name: formatMessage({
              id: 'app.widgets.task.additional_managers.abbreviated.header',
              defaultMessage: 'Additional Mgr(s)',
            }),
            field: 'additional_managers',
            format: FORMAT_AVATAR_GROUP,
            multi: true,
            csvFormat: formatNamesForCsv,
            sort: (a, b) =>
              peopleArrayCountThenNameSort(
                a.additional_managers,
                b.additional_managers
              ),
          },
        ],
        []
      ),
      {
        field: 'cost_center',
        name: formatMessage({
          id: 'app.views.administration.campaign_participants_add_people_modal.table.cost_center',
          defaultMessage: 'Cost center',
        }),
        popoverContent: (
          <FormattedMessage
            id="app.views.administration.campaign_participants_add_people_modal.table.cost_center_popover"
            defaultMessage="Cost center"
          />
        ),
      },
      {
        field: 'department',
        name: formatMessage({
          id: 'app.views.administration.campaign_participants_add_people_modal.table.department',
          defaultMessage: 'Department',
        }),
        popoverContent: (
          <FormattedMessage
            id="app.views.administration.campaign_participants_add_people_modal.table.department_popover"
            defaultMessage="Department"
          />
        ),
      },
      {
        field: 'business_unit',
        name: formatMessage({
          id: 'app.views.administration.campaign_participants_add_people_modal.table.business_unit',
          defaultMessage: 'Business unit',
        }),
        popoverContent: (
          <FormattedMessage
            id="app.views.administration.campaign_participants_add_people_modal.table.business_unit_popover"
            defaultMessage="Business unit"
          />
        ),
      },
      {
        field: 'level_id',
        name: formatMessage({
          id: 'app.views.administration.campaign_participants_add_people_modal.table.level',
          defaultMessage: 'Level',
        }),
        popoverContent: (
          <FormattedMessage
            id="app.views.administration.campaign_participants_add_people_modal.table.level_popover"
            defaultMessage="Level ID"
          />
        ),
      },
      {
        field: 'latest_hire_date',
        name: formatMessage({
          id: 'app.views.administration.campaign_participants_add_people_modal.table.hired',
          defaultMessage: 'Hired',
        }),
        popoverContent: (
          <FormattedMessage
            id="app.views.administration.campaign_participants_add_people_modal.table.hired_popover"
            defaultMessage="Latest hire date"
          />
        ),
      },
    ],
    [formatMessage, ifEnabled]
  );

  const formContents = useMemo(
    () => (
      <>
        {status === 'LOADING' && <Loading />}
        {status === 'SUCCESS' && rows.length > 0 && (
          <FilterablePeopleTable
            personIsTopLevelObject={true}
            rows={rows}
            columns={columns}
            hideFilters={false}
            hideExportButton={true}
            isRowSelectable={() => true}
            toggleSelectedRowField={toggleSelectedRowField}
            selectedRowToggleFieldsSet={selectedRowIdsSet}
            setSelectedRowToggleFieldsSet={setSelectedRowIdsSet}
            getUniqueRowId={getId}
            initialTableSize={rows.length + 1}
          />
        )}
        {status === 'SUCCESS' && rows.length === 0 && (
          <EmptyState
            title={formatMessage({
              id: 'app.views.administration.campaign_participants_add_people_modal.empty_state.title',
              defaultMessage:
                'All people in your organization are already included',
            })}
            subtitle={
              hasHrisIntegration
                ? formatMessage(
                    {
                      id: 'app.views.administration.campaign_participants_add_people_modal.empty_state.subtitle_hris',
                      defaultMessage:
                        'Everyone in your organization is already participating in {cycle_name}.<br></br><br></br>If you do not see someone that is in your HRIS, wait for the HRIS integration to sync with Confirm (this typically happens every 24 hours but may vary by organization), then come back here and try again.',
                    },
                    {
                      cycle_name: campaign.name,
                      br: () => <br />,
                    }
                  )
                : formatMessage(
                    {
                      id: 'app.views.administration.campaign_participants_add_people_modal.empty_state.subtitle',
                      defaultMessage:
                        'Everyone in your organization is already participating in {cycle_name}.<br></br><br></br>If you would like to add additional people to your organization, you can do so in <link>People administration</link>.',
                    },
                    {
                      cycle_name: campaign.name,
                      br: () => <br />,
                      link: (chunks) => (
                        <a href={ADMINISTRATION_PEOPLE(formatMessage).path}>
                          {chunks}
                        </a>
                      ),
                    }
                  )
            }
          ></EmptyState>
        )}
      </>
    ),
    [
      campaign.name,
      columns,
      formatMessage,
      hasHrisIntegration,
      rows,
      selectedRowIdsSet,
      status,
      toggleSelectedRowField,
    ]
  );

  const submitText = useMemo(() => {
    return rows.length > 0 ? (
      <FormattedMessage
        id="app.views.administration.campaign_participants_add_people_modal.submit.title"
        defaultMessage="Add {count, plural, one {# person} other {# people}}"
        values={{
          count: selectedRowIdsSet.size,
        }}
      />
    ) : (
      <FormattedMessage
        id="app.views.administration.campaign_participants_add_people_modal.close.title"
        defaultMessage="Close"
      />
    );
  }, [rows.length, selectedRowIdsSet.size]);

  const object = useMemo(() => {
    return { person_ids: Array.from(selectedRowIdsSet) };
  }, [selectedRowIdsSet]);

  return (
    <ModalEditor
      size="xl"
      fullscreen="lg"
      isOpen={isOpen}
      toggle={onToggle}
      method="POST"
      url={`/datasets/${campaign.dataset_id}/add-people`}
      title={formatMessage({
        id: 'app.views.administration.campaign_participants_add_people_modal.modal.title',
        defaultMessage: 'Add participants',
      })}
      submitText={submitText}
      object={object}
      callback={onSuccess}
      triggerCallbackBeforeClose={true}
      disabled={rows.length > 0 && selectedRowIdsSet.size === 0}
    >
      {formContents}
    </ModalEditor>
  );
};

export default CampaignParticipantsAddPeopleModal;
